import React, { useContext, useEffect, useState } from "react";

import SideBarCourse from "./SideBarCourse";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import { Link, useParams } from "react-router-dom";
import VideoPopupModal from "./VideoPopupModal";
import SatisfactionScaleModal from "./../login/SatisfactionScaleModal";
import PainScaleModal from "./../login/PainScaleModal";
import VideoComponent from "../media/VideoComponent";
import ActivityScaleModal from "../login/ActivityScaleModal";

const Course = () => {
  const {
    getDynamicUserQuestionPlaylistApi,
    nextVideoWatchApi,
    removePlaylistApi,
  } = useApiAxios();
  const [selectedVideoModule, setSelectedVideoModule] = useState([]);
  const [currentVideoToPlay, setCurrentVideoToPlay] = useState({});
  const [currentUserIdVideo, setCurrentUserIdVideo] = useState(null);
  const [lastVideo, setLastVideo] = useState(false);
  const [buttonVideoTitle, setButtonVideoTitle] = useState(null);
  const [activePlaylistIndex, setActivePlaylistIndex] = useState(0);
  console.log(buttonVideoTitle);
  console.log(currentVideoToPlay);
  const [videoPopupModal, setVideoPopupModal] = useState(false);
  const {
    showSatisfactionModal,
    setShowSatisfactionModal,
    showPainModal,
    setShowPainModal,
    showAvgActivityModal,
    setShowAvgActivityModal,
  } = useContext(Context);

  const [index, setIndex] = useState(0);

  const { id } = useParams();
  const init = async () => {
    const { data, error } = await getDynamicUserQuestionPlaylistApi(id);

    if (!error) {
      setSelectedVideoModule(data.data.userVideoModule);
      let foundUncompletedVideo = false;
      if (data.data.userVideoModule.length === 0) {
        await removePlaylistApi();
      }

      for (let index = 0; index < data.data.userVideoModule.length; index++) {
        console.log("3");
        const element = data.data.userVideoModule[index];

        for (let index2 = 0; index2 < element.userVideos.length; index2++) {
          console.log("4");
          const element2 = element.userVideos[index2];
          if (!element2.completed && !foundUncompletedVideo) {
            setCurrentVideoToPlay(element2.video);
            setActivePlaylistIndex(index);
            setCurrentUserIdVideo(element2._id);
            foundUncompletedVideo = true;
            if (index2 + 1 === element.userVideos.length) {
              setButtonVideoTitle(
                data?.data?.userVideoModule[index + 1]?.videoModule?.title
              );
            } else {
              setButtonVideoTitle(null);
            }
            if (
              index + 1 === data.data.userVideoModule.length &&
              index2 + 1 === element.userVideos.length
            ) {
              setLastVideo(true);
            } else {
              setLastVideo(false);
            }
          }
        }
        if (foundUncompletedVideo) {
          break;
        }
      }
    }
  };
  useEffect(() => {
    init();
  }, []);
  const [forceUpdate, setForceUpdate] = useState(false);

  // Update the state variable whenever the video URL changes
  useEffect(() => {
    setForceUpdate((prevState) => !prevState);
  }, [currentVideoToPlay?.filename]);
  const nextVideoHandler = async (id) => {
    await nextVideoWatchApi(id);
    init();
  };
  const videoModuleEnd = () => {
   
    setShowSatisfactionModal(true);
  };

  const videoModuleEndShow = () => {
    setVideoPopupModal(true);
  
  };
const videoModuleEndEnd =()=>{
  setShowSatisfactionModal(true);
}
  return (
    <>
      <SideBarCourse
        selectedVideoModule={selectedVideoModule}
        setCurrentVideoToPlay={setCurrentVideoToPlay}
        currentVideoToPlay={currentVideoToPlay}
        setCurrentUserIdVideo={setCurrentUserIdVideo}
        activePlaylistIndex={activePlaylistIndex}
        setActivePlaylistIndex={setActivePlaylistIndex}
      >
        <div className=" bg-white">
          <div className="page-content pe-0 pt-0">
            <div className="container-fluid">
              <div className="">
                <div className="container-fluid mb-4 mb-xl-5 z-10">
                  <div className="row">
                    <div className="class_control">
                      <div className="row mb-4 d-flex align-items-center">
                        <div className="col-12 text-end"></div>
                        <div className="col-sm-12 col-md-8">
                          <h4 className="chaptor_name">
                            {currentVideoToPlay?.title}
                          </h4>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb_24 container-ped">
                      <form id="msform">
                        <fieldset>
                          <div className="video_play_area text-start ">
                            <a href="#!" data-bs-target="#addcompany">
                              <VideoComponent
                                key={forceUpdate}
                                src={`${currentVideoToPlay?.filename}`}
                              />
                            </a>
                          </div>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: currentVideoToPlay?.description,
                            }}
                          />
                        </fieldset>
                        <div className="d-flex step-footer">
                          {lastVideo ? (
                            <Link
                              onClick={() => {
                              
                                videoModuleEndEnd();
                               // nextVideoWatchApi(currentUserIdVideo);
                                // removePlaylistApi();
                              }}
                              className={`step_btn d-inline-block ms-auto `}
                            >
                              I Am Done
                            </Link>
                          ) : (
                            <Link
                              onClick={() =>
                                !buttonVideoTitle
                                  ? nextVideoHandler(currentUserIdVideo)
                                  : videoModuleEnd()
                              }
                              className={`step_btn d-inline-block ms-auto `}
                            >
                              {buttonVideoTitle
                                ? buttonVideoTitle
                                : "Next Video"}
                            </Link>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SideBarCourse>
      <VideoPopupModal
        show={videoPopupModal}
        handleClose={() => {
          setVideoPopupModal(false);
          setIndex(0);
        }}
        nextVideoHandler={nextVideoHandler}
        currentUserIdVideo={currentUserIdVideo}
        removePlaylistApi={removePlaylistApi}
        index={index}
        setIndex={setIndex}
        lastVideo={lastVideo}
      />
      <PainScaleModal
        show={showPainModal}
        handleClose={() => setShowPainModal(false)}
      />
      <SatisfactionScaleModal
        show={showSatisfactionModal}
        handleClose={() => {
          setShowSatisfactionModal(false);
          if (buttonVideoTitle) {
            setVideoPopupModal(true);
          } else if (lastVideo){
            videoModuleEndShow()
          }
        }}
      />
      <ActivityScaleModal
        show={showAvgActivityModal}
        handleClose={() => setShowAvgActivityModal(false)}
      />
    </>
  );
};

export default Course;
