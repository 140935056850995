import React, { useContext } from "react";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";

const WellnessVideoLibrary = () => {
  const { content } = useContext(Context);
  return (
    <div>
      <div className="video_shade">
        <div className="container mb-4 mb-xl-5 pt-4 pt-xl-5 ">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="home_title text-center mb-3">{content?.t9}</h1>
              <p className="home_subtext text-center">{content?.t10}</p>
            </div>
          </div>
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
              <div className="card video_card h-100">
                <ImageComponent
                  className="card-img-top"
                  src={`${content?.img4}`}
                />

                <div className="card-body">
                  <h5 className="card-title">{content?.t11}</h5>
                  <p className="card-text">{content?.t12}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
              <div className="card video_card h-100">
                <ImageComponent
                  className="card-img-top"
                  src={`${content?.img5}`}
                />
                <div className="card-body">
                  <h5 className="card-title">{content?.t13}</h5>
                  <p className="card-text">{content?.t14}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
              <div className="card video_card h-100">
                <ImageComponent
                  className="card-img-top"
                  src={`${content?.img6}`}
                />
                <div className="card-body">
                  <h5 className="card-title">{content?.t15}</h5>
                  <p className="card-text">{content?.t16}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
              <div className="card video_card h-100">
                <ImageComponent
                  className="card-img-top"
                  src={`${content?.img7}`}
                />
                <div className="card-body">
                  <h5 className="card-title">{content?.t17}</h5>
                  <p className="card-text">{content?.t18}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
              <div className="card video_card h-100">
                <ImageComponent
                  className="card-img-top"
                  src={`${content?.img8}`}
                />
                <div className="card-body">
                  <h5 className="card-title">{content?.t19}</h5>
                  <p className="card-text">{content?.t20}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 mb_24">
              <div className="card video_card h-100">
                <ImageComponent
                  className="card-img-top"
                  src={`${content?.img9}`}
                />
                <div className="card-body">
                  <h5 className="card-title">{content?.t21}</h5>
                  <p className="card-text">{content?.t22}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WellnessVideoLibrary;
