import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Question1 = () => {
  const [activities, setActivities] = useState([]);
  const [currentActivity, setCurrentActivity] = useState("");
  const [currentRating, setCurrentRating] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [userSelectedYes, setUserSelectedYes] = useState(null);
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setCurrentActivity(e.target.value);
  };

  const handleRatingChange = (e) => {
    setCurrentRating(Number(e.target.value));
  };

  const handleYesNoChange = (e) => {
    setUserSelectedYes(e.target.value === 'yes');
  };

  const addActivity = () => {
    if (currentActivity && currentRating >= 0 && currentRating <= 10) {
      setActivities([...activities, { activity: currentActivity, rating: currentRating }]);
      setCurrentActivity("");
      setCurrentRating(0);
      setShowModal(true);
    } else {
      // Handle invalid input (e.g., show an error message)
      alert("Please enter a valid activity and rating (0 to 10).");
    }
  };

  const handleSubmit = () => {
    if (userSelectedYes) {
      alert("Navigating to Step 2...");
      navigate("/question2");

    } else {
      console.warn("Navigating to home...");
      alert("Navigating to Step 2...");
      setShowModal(false);
      navigate("/question2");
    }
    setShowModal(true);
  };

 

  return (
    <div className="container mt-4">
      <h1>
        Are you currently unable to perform important activities or experiencing
        difficulty due to a current problem or pain? Like putting on socks or
        going shopping
      </h1>
      <div className="alert alert-success" role="alert">
        Example: Sitting, Bending, Lifting, Reaching, Picking Items up off the Floor, Carrying Weight, Sleeping
      </div>
      <div className="form-group">
        <label>Are you currently experiencing difficulty with activities?</label>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            name="yesNoInput"
            value="yes"
            checked={userSelectedYes}
            onChange={handleYesNoChange}
          />
          <label className="form-check-label">Yes</label>
        </div>
        <div className="form-check">
          <input
            type="radio"
            className="form-check-input"
            name="yesNoInput"
            value="no"
            onChange={handleYesNoChange}
          />
          <label className="form-check-label">No</label>
        </div>
      </div>

      {userSelectedYes && (
        <div>
          <div className="form-group">
            <label htmlFor="activitySelect">Select the activity:</label>
            <select
              id="activitySelect"
              name="activitySelect"
              className="form-control"
              value={currentActivity}
              onChange={handleInputChange}
              style={{ width: '20%' }}
            >
              <option value="" disabled>Select an activity</option>
              <option value="sitting">Sitting</option>
              <option value="bending">Bending</option>
              <option value="lifting">Lifting</option>
              <option value="reaching">Reaching</option>
            </select>
          </div>
          <div className="form-group">
            <label>Select a rating (0 to 10):</label>
            {[...Array(11).keys()].map((num) => (
              <div className="form-check form-check-inline" key={num}>
                <input
                  type="radio"
                  className="form-check-input"
                  name="ratingInput"
                  value={num}
                  checked={currentRating === num}
                  onChange={handleRatingChange}
                />
                <label className="form-check-label">{num}</label>
              </div>
            ))}
          </div>
          <button className="btn btn-primary" onClick={addActivity}>
            Add Activity
          </button>
        </div>
      )}

      {showModal && (
        <div className="modal-content mt-3 p-3 border rounded" style={{ width: '40%' }}>
          <h2>Rating Modal</h2>
          {activities.map((activityObj, index) => (
            <p key={index}>
              Activity: {activityObj.activity}, Rating: {activityObj.rating}
            </p>
          ))}
        </div>
      )}

      <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem' }}>
        <button className="btn btn-success" onClick={handleSubmit}>
          Submit
        </button>
        
      </div>
    </div>
  );
};

export default Question1;
