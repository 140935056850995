import * as React from "react";
import styles from "./loader.module.css";

export default function Loader({ open }) {
  return (
   
    <div
      style={{ visibility: open ? "visible" : "hidden", zIndex: 1000000000 }}
      className={styles.loading}
    >
      Loading&#8230;
    </div>
  );
}
