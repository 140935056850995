import React, { useEffect, useState, useRef } from "react";
import { Link,  useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import LandingPage from "./LandingPage";
import WellnessVideoLibrary from "../wellNessVideo/WellnessVideo";
const OfferById = (   ) => {

  const [offer, setOffer] = useState({});
  const { id } = useParams(); // Get the ID from the URL
  const { getOfferById } = useApiAxios();
  const pricingSectionRef = useRef(null); // Create a ref for the pricing section

  const fun = async () => {
    const data = await getOfferById(id);
    if (!data.error) {
      setOffer(data.data.data);
    }
  };
  useEffect(() => {
    fun();
  }, []);

  const paymentHanded = (id) => {
    if (offer.createdFor === "physical-therapists") {
      window.open(
        `${process.env.REACT_APP_ADMIN_URL}/payment/${offer._id}/${id}`
      );
    } else {
      if (offer.createdFor === "patient") {
        window.open(
          `${process.env.REACT_APP_DOMAIN}/payment/${offer._id}/${id}`
        );
      } else {
        window.open(
          `${process.env.REACT_APP_DOMAIN}/payment/company/${offer._id}/${id}`
        );
      }
    }
  };
  return offer.createdFor === "physical-therapists" ? (
    <>
      <div className="container banner_area px-3 px-md-0">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-11 col-xl-9 z-10">
            <h1 className="step_title text-center mb-3">HANNA HEALTH</h1>
            <h4 className="step_semititle text-center">
              Hanna Health is a wellness video platform offering hundreds of exercises,
              classes, and educational content designed to enhance people's
              quality of life through movement.
            </h4>
          </div>
        </div>
      </div>
      <WellnessVideoLibrary
        showHello={true}
        paymentHanded={paymentHanded}
        offer={offer}
      />
      <div ref={pricingSectionRef}>
        <div className="container mt-4 my-xl-5 pt-4 pt-xl-5">
          <div className="row pt-4 pt-xl-5 justify-content-center">
            <div className="col-xl-7">
              <p className="home_subtext text-center">GET STARTED TODAY!</p>
              <h1 className="home_title mb-3 text-center">
                Our Pricing &amp; Plans
              </h1>
            </div>
          </div>
        </div>
        {/* Subscription end */}
        {/* Pricing Start*/}
        <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
          <div className="row">
            {/* Purple Table */}
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="pricing-table red">
                {/* 12 months Table Head */}
                <div className="text-center">
                  <div className="pricing-label text-center">12 months</div>
                </div>
                <h2 className="text-center">Pro</h2>
                <h5 className="text-center">
                  ${`${offer?.year?.price}`} billed every 12 months*
                </h5>
               
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  
                  <span className="amount">
                    {Math.round(offer?.year?.price / 12)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.year?.stripePriceId
                        : offer?.year?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
            {/* Turquoise Table */}
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="pricing-table turquoise">
                {/* 6 months Table Head */}
                <div className="text-center">
                  <div className="pricing-label">6 months</div>
                </div>
                <h2 className="text-center">Extended</h2>
                <h5 className="text-center">
                  ${offer?.sixMonth?.price} billed every 6 months*
                </h5>
             
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">
                    {Math.round(offer?.sixMonth?.price / 6)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.sixMonth?.stripePriceId
                        : offer?.sixMonth?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
            {/* 3 months Table */}
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="pricing-table purple">
                {/* Table Head */}
                <div className="text-center">
                  <div className="pricing-label text-center">3 months</div>
                </div>
                <h2 className="text-center">Basic</h2>
                <h5 className="text-center">
                  ${offer?.threeMonth?.price} billed every 3 months*
                </h5>
               
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">
                    {Math.round(offer?.threeMonth?.price / 3)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.threeMonth?.stripePriceId
                        : offer?.threeMonth?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Priceing End*/}
      </div>
    </>
  ) : offer.createdFor === "patient" ? (
    <>
      <LandingPage
        paymentHanded={paymentHanded}
        offer={offer}
        scrollToPricingSection={() => {
          // Scroll to the pricing section when called
          pricingSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }}
      />

      <div>
        <div className="container mt-4 my-xl-5 pt-4 pt-xl-5">
          <div className="row pt-4 pt-xl-5 justify-content-center">
            <div className="col-xl-7" ref={pricingSectionRef}>
              <p className="home_subtext text-center">GET STARTED TODAY!</p>
              <h1 className="home_title mb-3 text-center">
                Our Pricing &amp; Plans
              </h1>
            </div>
          </div>
        </div>
        {/* Subscription end */}
        {/* Pricing Start*/}
        <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
          <div className="row">
            {/* Purple Table */}
            <div className="col-md-4 mb-4 mb-md-4">
              <div className="pricing-table purple">
                {/* 12 months Table Head */}
                <div className="text-center">
                  <div className="pricing-label text-center">12 months</div>
                </div>
                <h2 className="text-center">Pro</h2>
                <h5 className="text-center">
                  ${`${offer?.year?.price}`} billed every 12 months*
                </h5>
               
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                
                  <span className="amount">
                    {Math.round(offer?.year?.price / 12)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.year?.stripePriceId
                        : offer?.year?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
            {/* Turquoise Table */}
            <div className="col-md-4 mb-4 mb-md-4">
              <div className="pricing-table turquoise">
                {/* 6 months Table Head */}
                <div className="text-center">
                  <div className="pricing-label">6 months</div>
                </div>
                <h2 className="text-center">Extended</h2>
                <h5 className="text-center">
                  ${offer?.sixMonth?.price} billed every 6 months*
                </h5>
              
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">
                    {Math.round(offer?.sixMonth?.price / 6)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.sixMonth?.stripePriceId
                        : offer?.sixMonth?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
            {/* 3 months Table */}
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="pricing-table red">
                {/* Table Head */}
                <div className="text-center">
                  <div className="pricing-label text-center">3 months</div>
                </div>
                <h2 className="text-center">Basic</h2>
                <h5 className="text-center">
                  ${offer?.threeMonth?.price} billed every 3 months*
                </h5>
              
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">
                    {Math.round(offer?.threeMonth?.price / 3)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.threeMonth?.stripePriceId
                        : offer?.threeMonth?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Priceing End*/}
        {/* serve */}
        <div className="container mb-4 mb-xl-5 pt-4">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <h1 className="home_title text-center mb-3">
                *7-Day Money-Back Guarantee
              </h1>
              <p className="home_subtext text-center">
                If you aren’t completely satisfied with Hanna Health Wellness and
                Prevention Program, let us know within the first 7-days for a
                full refund. No questions asked.
              </p>
            </div>
          </div>
        </div>
        {/* FAQ  */}
        <div className="container mb-4 mb-xl-5 pb-4 pb-xl-5">
          <div className="row justify-content-center">
            <div className="col-xl-12">
              <p className="home_subtext"></p>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Are my results guaranteed?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      Like anything in life, you will get out of this program
                      what you put into it. While we do offer a 7-day money-back
                      guarantee, the results you get are up to you. We can
                      promise that if you commit to the process and put in the
                      work, you will see results.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Can I do the exercises if I have pain?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      The intent of the program is to get you moving and improve
                      your mobility as well as your strength, therefore reducing
                      pain and preventing injuries. If you feel the pain is
                      preventing you from performing the exercises, we suggest
                      you schedule a  physical therapy visit with our
                      provider partner.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What if I don’t have time to do the exercises?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      All the videos are short in duration so you can fit them
                      easily into your daily routine. Our intention is to make
                      it very simple and practical so you actually do them.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      I loved this program and want more!
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      If you would like to see different content, please email
                      your suggestions to   <Link href="mailto:admin@hannahealth.com" target="_blank">
                      admin@hannahealth.com
                      </Link>
                      .
                    </div>
                    
                  </div>
                </div>
              </div>

              <Link
                    className="btn virt_btn green"
                    onClick={()=>  pricingSectionRef.current.scrollIntoView({ behavior: "smooth" })} // Call scrollToPricingSection
                  >
                    YES, I WANT TO FEEL BETTER
                  </Link>


                   
              <p />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="container banner_area px-3 px-md-0">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-11 col-xl-9 z-10">
            <h1 className="step_title text-center mb-3">HANNA HEALTH SOLUTION</h1>
            <h4 className="step_semititle text-center">
              INJURY PREVENTION AND FUNCTION IMPROVEMENT
            </h4>
            <p className="home_subtext text-center">
              Improve employees' quality of life through high-quality education
              and movement to enhance productivity, attract and retain talent,
              reduce costs, and minimize lost workdays.
            </p>
          </div>
        </div>
      </div>
      <WellnessVideoLibrary
        showHello={true}
        paymentHanded={paymentHanded}
        offer={offer}
      />
      <div>
        <button
          onClick={() =>
            pricingSectionRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className="btn virt_btn green"
        >
          YES, I WANT A HEALTHIER BUSINESS
        </button>
      </div>
      <div className="container my-4 my-xl-5 pt-4 pt-xl-5">
        <div className="row py-4">
          <div className="col-sm-12">
            <h1 className="home_title text-center mb-4 mb-xl-5">
              Empower your team and supercharge your company's vitality
            </h1>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                Boost <strong>workforce efficiency and productivity</strong> by
                championing physical activity and proactively addressing
                injuries.
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <strong>Minimize lost workdays</strong> or bed days caused by
                pain or medical issues.
              </li>
              
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                Offer enticing ancillary benefits to attract and{" "}
                <strong>retain top talent</strong>, curbing turnover rates.
              </li>
            
              <li>
                <i className="fas fa-check-circle" />
                Reduce future <strong>healthcare costs</strong> by preventing
                injuries that might otherwise lead to costly procedures such as
                MRI scans, injections, or surgeries.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={() =>
            pricingSectionRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className="btn virt_btn green"
        >
          YES, I WANT TO EMPOWER MY TEAM
        </button>
      </div>
      <div ref={pricingSectionRef}>
        <div className="container mt-4 my-xl-5 pt-4 pt-xl-5">
          <div className="row pt-4 pt-xl-5 justify-content-center">
            <div className="col-xl-7">
              <p className="home_subtext text-center">GET STARTED TODAY!</p>
              <h1 className="home_title mb-3 text-center">
                Our Pricing &amp; Plans
              </h1>
            </div>
          </div>
        </div>
        {/* Subscription end */}
        {/* Pricing Start*/}
        <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
          <div className="row">
            {/* Purple Table */}
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="pricing-table red ">
                {/* 12 months Table Head */}
                <div className="text-center">
                  <div className="pricing-label text-center">12 months</div>
                </div>
                <h2 className="text-center">Pro</h2>
                <h5 className="text-center">
                  ${`${offer?.year?.price}`} billed every 12 months*
                </h5>
             
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">
                    {Math.round(offer?.year?.price / 12)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.year?.stripePriceId
                        : offer?.year?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
            {/* Turquoise Table */}
            <div className="col-md-4 mb-4 mb-md-0">
              <div className="pricing-table turquoise">
                {/* 6 months Table Head */}
                <div className="text-center">
                  <div className="pricing-label">6 months</div>
                </div>
                <h2 className="text-center">Extended</h2>
                <h5 className="text-center">
                  ${offer?.sixMonth?.price} billed every 6 months*
                </h5>
               
                {/* Features */}
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">
                    {Math.round(offer?.sixMonth?.price / 6)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.sixMonth?.stripePriceId
                        : offer?.sixMonth?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
            {/* 3 months Table */}
            <div className="col-md-4 mb-4 mb-md-4">
              <div className="pricing-table purple ">
                {/* Table Head */}
                <div className="text-center">
                  <div className="pricing-label text-center">3 months</div>
                </div>
                <h2 className="text-center">Basic</h2>
                <h5 className="text-center">
                  ${offer?.threeMonth?.price} billed every 3 months*
                </h5>
               
                <div className="pricing-features">
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Pilates & functional training
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Stretching classes and mobility exercises
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Self-care movement plan
                  </div>
                  <div className="feature">
                    <i className="fa fa-check" aria-hidden="true" />
                    Ergonomics & body mechanics
                  </div>
                </div>
                {/* Price */}
                <div className="price-tag">
                  <span className="symbol">$</span>
                  <span className="amount">
                    {Math.round(offer?.threeMonth?.price / 3)}
                  </span>
                  <span className="after">/month</span>
                </div>
                {/* Button */}
                <Link
                  onClick={() =>
                    paymentHanded(
                      offer.createdFor === "physical-therapists"
                        ? offer?.threeMonth?.stripePriceId
                        : offer?.threeMonth?.stripePlanId
                    )
                  }
                  className="price-button rounded-5"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* Priceing End*/}
      </div>
    </>
  );
};

export default OfferById;
