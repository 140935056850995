import React, { useState } from "react";
import bodyPain from "./../json/bodyPart.json";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import bodySvg from "./../../assets/images/Untitled.jpg";

const SelectBodyPart = () => {
  const [bodyName, setBodyName] = useState(null);
  const { painLabel } = useParams();
  const navigate = useNavigate();
  function isNumberInRange(value) {
    // Check if the value is a number
    if (typeof value !== "number") {
      return false;
    }

    return value >= 0 && value <= 10;
  }
  useEffect(() => {
    if (!isNumberInRange(painLabel * 1)) {
      navigate("/question/select/pain/label");
    }
  }, [painLabel]);

  return (
    <div>
      <div className="container  px-3 px-md-0">
        <div className="row d-flex justify-content-center align-items-center pt-5 mt-5">
          <div className="col-xl-10 col-lg-10 z-10 pt-4">
            
            <h1 className="step_title text-center mb-3">
              Understanding Your Symptoms
            </h1>
            
          </div>
        </div>
      </div>

      <div className="">
        <div className="container mb-4 mb-xl-5 pt-3 z-10">
          <div className="row d-flex align-items-stretch justify-content-center">
            <div className="col-lg-11 col-xl-8 mb_24">
              <div className="step_area min-h-360">
                <div className="row">
                  <h4 className="question-title pb-3">
                    Where is your pain located?
                    <span className="text-danger">*</span>
                  </h4>
                 
                  <div className="col-md-8">
                    <img src={bodySvg} width={"100%"} alt="" />
                  </div>
                  <div className="col-md-4  ">
                    <div className="d-flex align-items-left flex-column justify-content-center     mt-5">
                      {bodyPain.map(({ name }) => (
                        <div className="form-check py-2 pe-2">
                          <input
                            className="form-check-input mr-1"
                            type="radio"
                            onClick={() => setBodyName(name)}
                            key={name}
                            defaultChecked=""
                            name="emp-1"
                            value={name}
                            id={name}
                          />
                          <label
                            className="form-check-label font14"
                            htmlFor={name}
                          >
                            {name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex step-footer">
                <Link
                  to={"/question/select/pain/label"}
                  className="step_btnoutline d-inline-block me-auto"
                >
                  Previous
                </Link>
                {bodyName ? (
                  <Link
                    to={`/question/goal/${painLabel}/${bodyName}`}
                    className="step_btn d-inline-block ms-auto"
                  >
                    Next
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectBodyPart;
