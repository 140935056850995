import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirection = () => {
  const navigator = useNavigate();
  useEffect(() => {
    navigator("/");
  }, []);

  return <div></div>;
};

export default Redirection;
