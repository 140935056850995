import React from "react";

const Privacy = () => {
  return (
    <div className="container mb-4 mb-xl-5 pt-5 pb-4 pb-xl-5">
      <div className="row py-4 py-xl-5 justify-content-center">
        <div className="col-xl-12 mt-5">
          <h1 className="home_title mb-3">Hanna Health Privacy Policy</h1>
          <p className="home_subtext">
            <em>Last modified: January 2023</em>
          </p>
          <div className="home_subtext">
            1. INTRODUCTION
            <br />
            <br />
            This Privacy Policy describes how Hanna Health collects and uses
            Personal Data about you through the use of our Website, mobile
            applications, and through email, text, text marketing, and other
            electronic communications between you and Hanna Health. Bright
            Minds, LLC (“Hanna Health’’ or “we,” “our,” or “us”) respects your
            privacy, and we are committed to protecting it through our
            compliance with this policy.
            <br />
            <br />
            This Privacy Policy (our “Privacy Policy”) describes the types of
            information we may collect from you or that you may provide when you
            visit or use our websites located at{" "}
            <a href="https://hannahealthhub.com/" target="_blank">
            https://hannahealthhub.com/
            </a>{" "}
            (our “Website”) or our Hanna Health mobile application (our “App”)
            as well as the online movement therapy services (“Services”)
            available to users through the Website and App, and our practices
            for collecting, using, maintaining, protecting, and disclosing that
            information.
            <br />
            <br />
            This policy applies to information we collect:
            <br />
            <br />
            <ul className="tearm_point">
              <li>on our Website and App;</li>
              <li>
                in email and other electronic messages between you and our
                Website and App;
              </li>
              <li>when you contact us by telephone;</li>
              <li>
                when you contact us and(or) interact with us through text
                marketing;
              </li>
              <li>
                when you interact with our advertising and applications on third
                party websites and services, if those applications or
                advertising include links to this policy.
              </li>
            </ul>
            It does not apply to information collected by:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                us offline or through any other means, including on any other
                website operated by Hanna Health or any third party;
              </li>
              <li>
                any third party, including through any application or content
                (including advertising) that may link to or be accessible from
                or on the Website or App.
              </li>
            </ul>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Website and Apps. By accessing or using our Website
            and/or App, you agree to this Privacy Policy. This Privacy Policy
            may change from time to time (see Changes to Our Privacy Policy).
            Your continued use of our Website or App after we make changes is
            deemed to be acceptance of those changes, so please check this
            Privacy Policy periodically for updates.
            <br />
            <br />
            Hanna Health can be used by physical therapists in connection with
            their provision of services to patients. Hanna Health does not
            provide any medical or health advice or services. When we collect
            health information for or on behalf of physical therapists in
            connection with providing or potentially providing treatment to
            patients, we do so as a “business associate” of the physical
            therapists and the information we collect may include protected
            health information (“PHI”) under the U.S. Health Insurance
            Portability and Accountability Act (“HIPAA”). In this regard, we
            enter into business associate agreements with physical therapists
            governing the use, privacy and security of PHI. We will use and
            disclose PHI only in accordance with the business associate
            agreements and HIPAA.
            <br />
            <br />
            We may de-identify your Personal Information so that it no longer
            reasonably identifies you. In this case, we may use this
            de-identified data without restriction and for any purpose,
            including to improve our Website, Application, and products and
            services.
            <br />
            <br />
            2. NO USERS UNDER THE AGE OF 18
            <br />
            <br />
            Our Website and App are not intended for users under the age of 18
            and users under the age of 18 are not permitted to use our Website
            or our App. We will remove any information about a user under the
            age of 18 if we become aware of it.
            <br />
            Our Website and App are not intended for user under 18 years of age.
            No one under age 18 may provide any information to or through the
            Website or App. We do not knowingly collect Personal Data from user
            under 18. If you are under 18, do not use or provide any information
            on our Website or in our Apps or on or through any of their
            features, including your name, address, telephone number, email
            address, or any screen name or user name you may use. If we learn we
            have collected or received Personal Data from a user under 18
            without verification of parental consent, we will delete that
            information. If you believe we might have any information from a
            user under 18, please contact us at https://admin.hannahealthhub.com/ or call
            us at (615) 212-5360.
            <br />
            <br />
            3. INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT
            <br />
            <br />
            We collect different types of information about you, including
            information that may directly identify you, information that is
            about you but individually does not personally identify you, and
            information that we combine with our other users. This includes
            information that we collect directly from you or through automated
            collection technologies.
            <br />
            <br />
            Generally
            <br />
            <br />
            We collect several types of information from and about users of our
            Website and App, specifically information:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                by which you may be personally identified, such as name, postal
                address, billing address, e-mail address, home and mobile
                telephone numbers, credit or debit card number (for payment
                purposes only), profession, physical condition and health
                information (“Personal Data” or “Personal Information”);
              </li>
              <li>
                that is about you but individually does not identify you, such
                as traffic data, logs, referring/exit pages, date and time of
                your visit to our Website or use of our App, error information,
                clickstream data, and other communication data and the resources
                that you access and use on the Website or through our App; or
              </li>
              <li>
                about your Internet connection, the equipment you use to access
                our Website or use our App and usage details.
              </li>
            </ul>
            We collect this information:
            <br />
            <br />
            <ul className="tearm_point">
              <li>directly from you when you provide it to us;</li>
              <li>
                automatically as you navigate through the Website or use our
                App. Information collected automatically may include estimated
                location, usage details, IP addresses, and information collected
                through cookies and other tracking technologies; and
              </li>
              <li>From third parties, for example, our business partners.</li>
            </ul>
            Information You Provide to Us
            <br />
            <br />
            The information we collect on or through our Website or through our
            App is:
            <br />
            <br />
            <ul className="tearm_point">
              <li>Personal Data such as the data identified above;</li>
              <li>
                Information that you provide by filling in forms on our Website
                or the App. This includes information provided at the time of
                registering to use our Website or App, using our Services or
                other services available through the Website for the App,
                purchasing products, or requesting further services. We may also
                ask you for information when you report a problem with our
                Website or App;
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses), if you contact us; and
              </li>
              <li>
                Details of transactions you carry out through our Website or
                through the App and of the fulfillment of your orders. You may
                be required to provide financial information before placing an
                order through our Website or App.
              </li>
            </ul>
            You also may provide information to be published or displayed
            (hereinafter, “posted”) on public areas of the Website or App or
            transmitted to other users of the Website or App or third parties
            (collectively, “User Contributions”). For example, you may post a
            question about a particular type of activity in our online forum or
            post achievements in the public areas of our Website or App. Your
            User Contributions are posted on and transmitted to others at your
            own risk. Although we limit access to certain pages, please be aware
            that no security measures are perfect or impenetrable. Additionally,
            we cannot control the actions of other users of the Website and App
            with whom you may choose to share your User Contributions.
            Therefore, we cannot and do not guarantee that your User
            Contributions will not be viewed by unauthorized persons.
            <br />
            <br />
            Information We Collect Through Automatic Data Collection
            Technologies
            <br />
            <br />
            As you navigate through and interact with our Website and App, we
            may use automatic data collection technologies to collect certain
            information about your equipment, browsing actions, and patterns,
            specifically:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                details of your visits to our Website or App, such as traffic
                data, location, logs, referring/exit pages, date and time of
                your visit to our Website or use of our App, error information,
                clickstream data, and other communication data and the resources
                that you access and use on the Website or in the App; and
              </li>
              <li>
                information about your computer, mobile device, and Internet
                connection, specifically your IP address, operating system,
                browser type, and App version information.
              </li>
            </ul>
            The information we collect automatically may include Personal Data
            or we may maintain it or associate it with Personal Data we collect
            in other ways or receive from third parties. It helps us to improve
            our Website and App and to deliver a better and more personalized
            service by enabling us to:
            <br />
            <br />
            <ul className="tearm_point">
              <li>estimate our audience size and usage patterns;</li>
              <li>
                store information about your preferences, allowing us to
                customize our Website and our App according to your individual
                interests;
              </li>
              <li>recognize you when you return to our Website and our App.</li>
            </ul>
            The technologies we use for this automatic data collection may
            include:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                Cookies (or browser cookies). We and our service providers may
                use cookies and other technologies to receive and store certain
                types of information whenever you interact with our Website and
                App through your computer or mobile device. A cookie is a small
                file or piece of data sent from a website and stored on the hard
                drive of your computer or mobile device. On your computer, you
                may refuse to accept browser cookies by activating the
                appropriate setting on your browser, and you may have similar
                capabilities on your mobile device in the preferences for your
                operating system or browser. However, if you select this setting
                you may be unable to access certain parts of our Website or use
                certain parts of our App. Unless you have adjusted your browser
                or operating system setting so that it will refuse cookies, our
                system will issue cookies when you direct your browser to our
                Website or use our App.
              </li>
              <li>
                Web Beacons. Pages of a Website, text marketing, or our e-mails,
                may contain small electronic files known as web beacons (also
                referred to as clear gifs. pixel tags and single-pixel gifs)
                that permit us, for example, to count users who have visited
                those pages, opened an e-mail or text marketing message and for
                other related website statistics (for example, recording the
                popularity of certain website content and verifying system and
                server integrity).
              </li>
              <li>
                Google Analytics. We use Google Analytics, a web analytics
                service provided by Google, Inc. (“Google”) to collect certain
                information relating to your use of the Website. Google
                Analytics uses “cookies”, which are text files placed on your
                computer, to help the Website analyze how users use the site.
                You can find out more about how Google uses data when you visit
                our Website by visiting “How Google uses data when you use our
                partners’ sites or apps”, (located at
                google.com/policies/privacy/partners/). We may also use Google
                Analytics Advertising Features or other advertising networks to
                provide you with interest-based advertising based on your online
                activity. For more information regarding Google Analytics please
                visit Google’s website, and pages that describe Google
                Analytics, such as{" "}
                <a
                  href="http://www.google.com/analytics/learn/privacy.html"
                  target="_blank"
                >
                  http://www.google.com/analytics/learn/privacy.html.
                </a>
              </li>
            </ul>
            4. HOW WE USE YOUR INFORMATION
            <br />
            <br />
            We use your Personal Data for various purposes described below,
            including to:
            <br />
            <br />
            provide our Website or App to you;
            <br />
            provide products and Services to you;
            <br />
            provide you with information you request from us;
            <br />
            enforce our rights arising from contracts;
            <br />
            We use information that we collect about you or that you provide to
            us, including any Personal Data:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                to provide our Website and its functionality, contents and
                services to;
              </li>
              <li>
                to provide our App and its functionality, contents and services;
              </li>
              <li>to provide our products and Services to you;</li>
              <li>
                to provide you with information, products, or services that you
                request from us or that may be of interest to you;
              </li>
              <li>
                to process, fulfill, support, and administer transactions and
                orders for products and Services ordered by you;
              </li>
              <li>to provide you with notices about your Hanna Health account;</li>
              <li>to contact you in response to a request;</li>
              <li>to fulfill any other purpose for which you provide it;</li>
              <li>
                to carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection;
              </li>
              <li>
                to notify you about changes to our Website, our App, or any
                products or services we offer or provide though them;
              </li>
              <li>
                in any other way we may describe when you provide the
                information; and
              </li>
              <li>for any other purpose with your consent.</li>
            </ul>
            We may also use your information to contact you about goods and
            services that may be of interest to you, including through
            newsletters, telephone, email and text marketing. If you wish to
            opt-out of receiving such communications, you may do so at any time
            by using the unsubscribe (and STOP for text marketing) mechanism
            within each of these communications . For more information, see
            Choices About How We Use and Disclose Your Information.
            <br />
            <br />
            5. DISCLOSURE OF YOUR INFORMATION
            <br />
            <br />
            We do not share, sell, or otherwise disclose your Personal Data for
            purposes other than those outlined in this Privacy Policy. We
            disclose your Personal Data to a few third parties, including:
            <br />
            <br />
            our affiliates and third party service providers that we use to
            support our business;
            <br />
            to a company we merge, acquire, or that buys us, or in the event of
            change in structure of our company of any form;
            <br />
            to comply with our legal obligations;
            <br />
            to affiliates and third parties for their own commercial purposes;
            <br />
            <br />
            We do not share, sell, or otherwise disclose your Personal Data for
            purposes other than those outlined in this Privacy Policy. However,
            we may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
            <br />
            <br />
            We may disclose Personal Data that we collect or you provide as
            described in this privacy policy:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                to affiliates, contractors, service providers, and other third
                parties we use to support our business. The services provided by
                these organizations include providing IT and infrastructure
                support services, and ordering, advertising, marketing, and
                payment processing services;
              </li>
              <li>
                to a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Hanna Health about
                our Website and App users are among the assets transferred;
              </li>
              <li>to fulfill the purpose for which you provide it;</li>
              <li>
                for any other purpose disclosed by us when you provide the
                information;
              </li>
              <li> with your consent.</li>
            </ul>
            We may also disclose your Personal Data:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                to comply with any court order, law, or legal process, including
                to respond to any government or regulatory request;
              </li>
              <li>
                to enforce or apply our Terms of Use{" "}
                <a href="https://hannahealthhub.com/terms/" target="_blank">
                https://hannahealthhub.com/terms/
                </a>{" "}
                and other agreements, including for billing and collection
                purposes; and
              </li>
              <li>
                if we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of Hanna Health, our customers,
                or others. This includes exchanging information with other
                companies and organizations for the purposes of fraud protection
                and credit risk reduction.
              </li>
            </ul>
            6. CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION
            <br />
            <br />
            We offer you choices on how you can opt out of our use of tracking
            technology, disclosure of your Personal Data for our advertising to
            you, and other targeted advertising.
            <br />
            We do not control the collection and use of your information
            collected by third parties described above in Disclosure of Your
            Information. These third parties may aggregate the information they
            collect with information from their other customers for their own
            purposes.
            <br />
            <br />
            In addition, we strive to provide you with choices regarding the
            Personal Data you provide to us.
            <br />
            We have created mechanisms to provide you with control over your
            Personal Data:
            <br />
            <br />
            <ul className="tearm_point">
              <li>
                Tracking Technologies and Advertising. You can set your browser
                or operating to refuse all or some cookies, or to alert you when
                cookies are being sent. If you disable or refuse cookies, please
                note that some parts of our Website or App may then be
                inaccessible or not function properly
              </li>
              <li>
                Promotional Offers from Hanna Health. If you do not wish to
                have your email address and(or) telephone number used by Hanna Health
                 to promote our own products and services, you can
                opt-out at any time by clicking the unsubscribe link at the
                bottom of any email or replying STOP to a text marketing
                message. You can opt-out any other marketing communications you
                receive from us by contacting us directly; allow 72 hours during
                hours of our operating business hours for requests to be
                administered. This opt out (unsubscribe) does not apply to
                information provided to Hanna Health as a result of a product
                purchase, or your use of our Services.
              </li>
              <li>
                Targeted Advertising. To learn more about interest-based
                advertisements and your opt-out rights and options, visit the
                Digital Advertising Alliance and the Network Advertising
                Initiative websites (aboutads.info and{" "}
                <a href="http://www.networkadvertising.org" target="_blank">
                  http://www.networkadvertising.org
                </a>
                ). Please note that if you choose to opt out, you will continue
                to see ads, but they will not be based on your online activity.
                We do not control third parties’ collection or use of your
                information to serve interest-based advertising. However, these
                third parties may provide you with ways to choose not to have
                your information collected or used in this way. You can also opt
                out of receiving targeted ads from members of the NAI on its
                website.
              </li>
            </ul>
            7. YOUR RIGHTS REGARDING YOUR INFORMATION AND ACCESSING AND
            CORRECTING YOUR INFORMATION
            <br />
            <br />
            You may review and change your personal information by contacting
            us.
            <br />
            You can review and change your Personal Data by logging using our
            Website or App. You may also notify us through the Contact
            Information below of any changes or errors in any Personal Data we
            have about you to ensure that it is complete, accurate, and as
            current as possible or to delete your account. We cannot delete your
            personal information except by also deleting your account with us.
            We may also not be able to accommodate your request if we believe it
            would violate any law or legal requirement or cause the information
            to be incorrect.
            <br />
            <br />
            8. DO NOT TRACK SIGNALS
            <br />
            <br />
            We may use automated data collection technologies to track you
            across websites. We currently do not honor do-not-track signals that
            may be sent by some browsers.
            <br />
            Some web browsers permit you to broadcast a signal to websites and
            online services indicating a preference that they “do not track”
            your online activities. At this time, we do not honor such signals
            and we do not modify what information we collect or how we use that
            information based upon whether such a signal is broadcast or
            received by us.
            <br />
            <br />
            9. DATA SECURITY
            <br />
            <br />
            Information transmitted over the Internet is not completely secure,
            but we do our best to protect your Personal Data. You can help
            protect your Personal Data and other information by keeping your
            password to our Website confidential. We have implemented measures
            designed to secure your Personal Data from accidental loss and from
            unauthorized access, use, alteration, and disclosure.
            <br />
            <br />
            The safety and security of your information also depends on you.
            Where you have chosen a password for the use of our Website or App,
            you are responsible for keeping this password confidential. We ask
            you not to share your password with anyone.
            <br />
            <br />
            Unfortunately, the transmission of information via the Internet is
            not completely secure. Although we do our best to protect your
            Personal Data, we cannot guarantee the security of your Personal
            Data transmitted to our Website or on or through our App. Any
            transmission of Personal Data is at your own risk. We are not
            responsible for circumvention of any privacy settings or security
            measures contained on the Website, in your operating system, or in
            the App.
            <br />
            <br />
            10. CALIFORNIA PRIVACY RIGHTS FOR CALIFORNIA RESIDENTS
            <br />
            <br />
            Under some circumstances, California law may provide you with the
            right to obtain a list of third parties (if any) that we provide
            your Personal Data to for their own direct marketing purposes.
            <br />
            California Civil Code Section 1798.83 (California’s “Shine the
            Light” law) permits users of our Website that are California
            residents and who provide Personal Information in obtaining products
            and services for personal, family, or household use to request
            certain information regarding our disclosure of Personal Information
            to third parties for their own direct marketing purposes. If
            applicable, this information would include the categories of
            Personal Information and the names and addresses of those businesses
            with which we shared your Personal Information with for the
            immediately prior calendar year (e.g. requests made in 2020 will
            receive information regarding such activities in 2019). You may
            request this information once per calendar year. To make such a
            request, please contact us by email at  admin@hannahealth.com
            <br />
            <br />
            11. CHANGES TO OUR PRIVACY POLICY
            <br />
            <br />
            We will post any changes to our Privacy Policy on our Website. If we
            make material changes to our Privacy Policy, we may notify you of
            such changes through your contact information and invite you to
            review (and accept, if necessary) the changes.
            <br />
            We may change this Privacy Policy at any time. It is our policy to
            post any changes we make to our Privacy Policy on this page with a
            notice that the Privacy Policy has been updated on the Website’s
            home page or the App’s home screen. If we make material changes to
            how we treat our users’ Personal Data, we will notify you by email
            to the email address specified in your account and/or through a
            notice on the Website’s home page or the App’s home screen. The date
            this Privacy Policy was last revised is identified at the top of the
            page. You are responsible for ensuring we have an up-to-date active
            and deliverable email address for you, and for periodically
            accessing the App or visiting our Website and reviewing this Privacy
            Policy to check for any changes.
            <br />
            <br />
            12. CONTACT INFORMATION
            <br />
            <br />
            You may contact us through the contact information below.
            <br />
            If you have any questions, concerns, complaints or suggestions
            regarding our Privacy Policy or otherwise need to contact us, you
            may contact us at the contact information below or through the
            “Contact Us” page on our Website or in the App.
            <br />
            <br />
            How to Contact Us:
            <br />
            <br />
            Bright Minds, LLC
            <br />
            Telephone: (615) 212-5360
            <br />
            Email:{" "}
            <a href="mailto: admin@hannahealth.com" target="_blank">
            admin@hannahealth.com
            </a>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
