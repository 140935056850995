import React from "react";
import landingImage2 from "../../assets/images/virt.png";

const VerticalDivideComponent = () => {
  return (
    <div
      className="container mt-5"
      style={{ backgroundColor: "#D0EBD4", padding: "20px" }}
    >
      <div className="row align-items-center">
        <div className="col-md-6 mb-4 mb-md-0">
          <img
            src={landingImage2}
            className="img-fluid rounded shadow-lg"
            alt="Placeholder"
          />
        </div>
        <div className="col-md-6 d-flex align-items-center justify-content-center">
          <div>
            <div className="container my-3 my-xl-4">
              <div className="row">
                <div className="col-sm-12">
                  <h1 className="blue_text fw-bold mb-3">
                    In The Next Few Weeks, You Could...
                  </h1>
                </div>
                <div className="col-sm-12">
                  <ul className="why-us">
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      <strong> Improve </strong> your Mobility.
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      Get <strong>Stronger</strong>.
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      Decrease <strong>Tension</strong>.
                    </li>
                  </ul>
                </div>
                <p className="home_subtext">
                  Performing the right movements can make all the difference.
                  We'll show you the best exercises.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalDivideComponent;
