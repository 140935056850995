import React from "react";
import styles from "./Thanks.module.css"; // Import the CSS module
import logo from "./images/logo.png";
const ReSuccess = () => {
  return (
    <div className={styles.overFlow}>
      <div className={styles.mainDiv}>
        <meta charSet="utf-8" />
        <title>Thank You</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="shortcut icon" href="path/to/assets/images/favicon.png" />

        {/* Thank You */}
        <div className={styles.logo}>
          <img src={logo} alt="Logo" />
        </div>
        <div className="d-flex justify-content-center align-items-center vh-100 w-100">
          <div className="text-center align-items-center w-100 m-auto">
            <div className="row">
              <div className="col-12 text-center">
                <div className={`mb-5 fw-bold ${styles.font20}`}>
                  PAYMENT WAS SUCCESSFUL
                </div>
                <div
                  className={`${styles.text_brittany} ${styles.thank_you_text}`}
                >
                  Thank You
                </div>
                <div className={`${styles.font26} mb-5`}>FOR SIGNING UP</div>

                <div className="pt-5 mb-3">
                  <a href="/" className={styles.start_btn}>
                    Resume
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReSuccess;
