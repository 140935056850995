import React  from "react";
import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import PaymentPageResubscription from "./PaymentPageResubscription";
const Subscription = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Elements stripe={stripePromise}>
      <PaymentPageResubscription />
    </Elements>
  );
};

export default Subscription;
