import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoFooter from "./../../assets/images/hanna1.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";

const Footer = () => {
  // Get current year
  const currentYear = new Date().getFullYear();
  const { createEmailSubscribe } = useApiAxios();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      await createEmailSubscribe({ email: values.email });
      resetForm();
    },
  });
  const [email, setEmail] = useState("");

  return (
    <div className="bottom_area">
      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-md-5">
            <img className="footer-logo" src={logoFooter} alt="" title="VIRT" />
            <p className="fl-subtext">
              Hanna Health is all about proactive lifestyle management!
              <br /> Here you will find exercises and resources to help you feel
              better, reduce pain and prevent injuries.
            </p>
            <a href="mailto:admin@hannahealth.com" className="inf-email">
              admin@hannahealth.com
            </a>
            <p className="social-text">Let's get Social!</p>
            <ul className="social-icon">
              <li>
                <Link
                  target="_blank"
                  to="https://www.facebook.com/hannahealthub/"
                >
                  <i className="fa-brands  fa-facebook" />
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.instagram.com/HANNAHEALTHHUB/"
                >
                  <i className="fa-brands  fa-instagram" />
                </Link>
              </li>
              <li>
                <Link target="_blank" to="https://www.youtube.com/@HannaHealth">
                  <i className="fa-brands  fa-youtube" />
                </Link>
              </li>
              <li>
                <Link
                  target="_blank"
                  to="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGxxzdiiWtuJwAAAZCcTseY2K_mcDRX77ilw1aHIGKSrNiPKGZCiGskR5woIu27lzjWwrJlJxonKXKs75LzYKSBiTWFdy7widAFA5DZHwft1QP2hTDPGgdLexfP4nSMDyIyHow=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fhannahealth"
                >
                  <i className="fa-brands   fa-linkedin" />
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-5">
            <h5 className="receive">Receive Email Updates</h5>
            <div className="input-group subscribe s ">
              <input
                type="text"
                id="email"
                name="email"
                className="form-control"
                placeholder="mail@simple.com"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />

              <Link
                className="input-group-text"
                onClick={() => {
                  setEmail("");
                  formik.handleSubmit();
                }}
                id="basic-addon1"
              >
                SUBSCRIBE
              </Link>
            </div>
            <div className="  s mb-3">
              {formik.touched.email && formik.errors.email && (
                <small className="text-danger">{formik.errors.email}</small>
              )}
            </div>
            <ul className="footer_link">
              <li>
                <Link to="/about">ABOUT</Link>
              </li>

              <li>
                <Link to="/contact">CONTACT</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <p className="copyright">
              <span>© Copyright {currentYear}. All Rights Reserved</span> &nbsp;
              | &nbsp; <Link to="/term">Terms</Link> &nbsp; | &nbsp;{" "}
              <Link to="/privacy">Privacy</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
