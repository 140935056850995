import React from 'react'

const Faq = () => {
    return (
        <div><div className="container mb-4 mb-xl-5 pt-5 pb-4 pb-xl-5">
            <div className="row py-4 py-xl-5 justify-content-center">
                <div className="col-xl-12 mt-5">
                    <h1 className="home_title text-center mb-3">
                        Frequently Asked Questions
                    </h1>
                    <p className="home_subtext"></p>
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button
                                    className="accordion-button"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Can I use my HSA card?
                                </button>
                            </h2>
                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    Yes, Physical Therapy is a medical service. We accept HSA cards.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    How do I schedule an appointment?
                                </button>
                            </h2>
                            <div
                                id="collapseTwo"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    If you are a patient, you can request an appointment directly
                                    through our website using the Book an Appointment function in the
                                    top right corner. You will be able to choose a convenient day and
                                    time. An email will be sent confirming your appointment.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    Do I need a doctor’s referral?
                                </button>
                            </h2>
                            <div
                                id="collapseThree"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingThree"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    In the state of Tennessee, you do not need a referral for physical
                                    therapy services. Patients may receive up to ninety (90) days of
                                    physical therapy treatment before the patient consults their
                                    primary physician. <br />
                                    <br />
                                    Patients who have been diagnosed as having a chronic,
                                    neuromuscular, or developmental condition by a physician, will be
                                    able to continue physical therapy treatment longer than ninety
                                    days without a doctor referral.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour"
                                    aria-expanded="false"
                                    aria-controls="collapseFour"
                                >
                                    What should I wear?
                                </button>
                            </h2>
                            <div
                                id="collapseFour"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    You should wear comfortable athlete clothes for movement.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive"
                                    aria-expanded="false"
                                    aria-controls="collapseFive"
                                >
                                    Do I need any accessories or props for the visit?
                                </button>
                            </h2>
                            <div
                                id="collapseFive"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingFive"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    After you schedule your visit, you will receive instructions with
                                    items to have handy with ideas on access to specific pieces of
                                    furniture to help with treatment.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSix"
                                    aria-expanded="false"
                                    aria-controls="collapseSix"
                                >
                                    Is there a discount for referring family and friends?
                                </button>
                            </h2>
                            <div
                                id="collapseSix"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSix"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    Yes, when your friend/family member has the first visit you will
                                    receive a $15 off on your next visit and they will receive a $15
                                    discount on their first visit.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="collapseSeven"
                                >
                                    How much does the visit cost?
                                </button>
                            </h2>
                            <div
                                id="collapseSeven"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingSeven"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    The first assessment consultation cost $99 and follow-up visits
                                    cost $85.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingEight">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseEight"
                                    aria-expanded="false"
                                    aria-controls="collapseEight"
                                >
                                    Do you accept insurance?
                                </button>
                            </h2>
                            <div
                                id="collapseEight"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingEight"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    No. We currently operate out-of-network and there are no
                                    affiliations with any insurance companies at this time. However,
                                    if you have a condition that warrants physical therapy
                                    intervention or other services typically covered by insurance a
                                    detailed invoice (called a super-bill) will be provided at your
                                    request so you can file with your insurance for out-of-network
                                    reimbursement. You may obtain information regarding your
                                    out-of-network physical therapy benefits by calling the number on
                                    the back of your insurance card.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingNine">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseNine"
                                    aria-expanded="false"
                                    aria-controls="collapseNine"
                                >
                                    Is it more expensive to receive treatment at Hanna Health Physical Therapy
                                    versus a traditional physical therapy clinic?
                                </button>
                            </h2>
                            <div
                                id="collapseNine"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingNine"
                                data-bs-parent="#accordionExample"
                            >
                                <div className="accordion-body">
                                    We are comparable. Our approach is centered on education,
                                    empowering the patient to self-manage their condition(s), focusing
                                    on re-injury prevention, and promoting independence. We believe
                                    our approach will likely reduce the need for PT in the future and
                                    save money.
                                    <br />
                                    <br />
                                    In a traditional Physical Therapy clinic, the patient may attend
                                    PT multiple sessions per week (2-3x). At Hanna HealthT Physical Therapy,
                                    the typical frequency is once a week for 3-6 weeks on average.
                                    <br />
                                    <br />
                                    Hanna Health offers patients one-on-one time with a physical therapist,
                                    who focuses 100% on the individual case, versus most clinics
                                    seeing 2-3 patients at the same time.
                                    <br />
                                    <br />
                                    Another point to consider is if you have a high deductible that
                                    hasn’t been met, you will likely pay the full visit fee either
                                    way.
                                    <br />
                                    <br />
                                    If you have a condition that warrants physical therapy
                                    intervention or other services typically covered by insurance, a
                                    detailed invoice (called a super-bill) will be provided at your
                                    request so you can file with your insurance for out-of-network
                                    reimbursement. Your insurance company may or may not approve the
                                    reimbursement. You may obtain information regarding your
                                    out-of-network physical therapy benefits by calling the number on
                                    the back of your insurance card.
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <p />
                </div>
            </div>
        </div>
        </div>
    )
}

export default Faq