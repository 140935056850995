import React from "react";
import { Link } from "react-router-dom";


const Offer = () => {
  return (
    <div>
      <div className="container mt-4 my-xl-5 pt-4 pt-xl-5">
        <div className="row pt-4 pt-xl-5 justify-content-center">
          <div className="col-xl-7">
            <p className="home_subtext text-center">GET STARTED TODAY!</p>
            <h1 className="home_title mb-3 text-center">
              Our Pricing &amp; Plans
            </h1>
            <h1 className="home_title mb-3 text-center">&amp; Plans</h1>
          </div>
        </div>
      </div>
      {/* Subscription end */}
      {/* Priceing Start*/}
      <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
        <div className="row">
          {/* Purple Table */}
          <div className="col-md-4">
            <div className="pricing-table red">
              {/* 12 months Table Head */}
              <div className="text-center">
                <div className="pricing-label text-center">12 months</div>
              </div>
              <h2 className="text-center">Pro</h2>
              <h5 className="text-center">$96 billed every 12 months*</h5>
              {/* Features */}
              <div className="pricing-features">
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Stretching - Take 5`s
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Full-Body Functional Training
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Mobility Exercises
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Ergonomics and Body Mechanics
                </div>
              </div>
              {/* Price */}
              <div className="price-tag">
                <span className="symbol">$</span>
                <span className="amount text-secondary text-decoration-line-through mx-2">
                  16
                </span>
                <span className="amount">8</span>
                <span className="after">/month</span>
              </div>
              {/* Button */}
              <Link className="price-button rounded-5" to="#">
                Get Started
              </Link>
            </div>
          </div>
          {/* Turquoise Table */}
          <div className="col-md-4">
            <div className="pricing-table turquoise">
              {/* 6 months Table Head */}
              <div className="text-center">
                <div className="pricing-label">6 months</div>
              </div>
              <h2 className="text-center">Extended</h2>
              <h5 className="text-center">$66 billed every 6 months*</h5>
              
              <div className="pricing-features">
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Stretching - Take 5`s
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Full-Body Functional Training
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Mobility Exercises
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Ergonomics and Body Mechanics
                </div>
              </div>
              {/* Price */}
              <div className="price-tag">
                <span className="symbol">$</span>
                <span className="amount">11</span>
                <span className="after">/month</span>
              </div>
              {/* Button */}
              <Link className="price-button rounded-5" to="#">
                Get Started
              </Link>
            </div>
          </div>
          {/* 3 months Table */}
          <div className="col-md-4 mb-4 mb-md-4">
            <div className="pricing-table purple">
              {/* Table Head */}
              <div className="text-center">
                <div className="pricing-label text-center">3 months</div>
              </div>
              <h2 className="text-center">Basic</h2>
              <h5 className="text-center">$45 billed every 3 months*</h5>
            
              {/* Features */}
              <div className="pricing-features">
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Stretching - Take 5`s
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Full-Body Functional Training
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Mobility Exercises
                </div>
                <div className="feature">
                  <i className="fa fa-check" aria-hidden="true" />
                  Ergonomics and Body Mechanics
                </div>
              </div>
              {/* Price */}
              <div className="price-tag">
                <span className="symbol">$</span>
                <span className="amount">15</span>
                <span className="after">/month</span>
              </div>
              {/* Button */}
              <Link className="price-button rounded-5" to="#">
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Priceing End*/}
      {/* serve */}
      <div className="container mb-4 mb-xl-5 pt-4">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <h1 className="home_title text-center mb-3">
              *7-Day Money-Back Guarantee
            </h1>
            <p className="home_subtext text-center">
            If you aren’t completely satisfied with the Hanna Wellness Program, let us know within the first 7-days for a full refund. No questions asked.
            </p>
          </div>
        </div>
      </div>
      {/* FAQ  */}
      <div className="container mb-4 mb-xl-5 pb-4 pb-xl-5">
        <div className="row justify-content-center">
          <div className="col-xl-12">
            <p className="home_subtext"></p>
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Are my results guaranteed?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    Like anything in life, you will get out of this program what
                    you put into it. While we do offer a 7-day money-back
                    guarantee, the results you get are up to you. We can promise
                    that if you commit to the process and put in the work, you
                    will see results.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Can I do the exercises if I have pain?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  The program intends to get you moving and improve your mobility as well as your strength, therefore reducing pain and preventing injuries. If you feel the pain is preventing you from performing the exercises, we suggest you schedule a virtual physical therapy visit with our provider partner.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    What if I don’t have time to do the exercises?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                  All the videos are short so you can fit them easily into your daily routine. We intend to make it very simple and practical so you actually do them.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingFour">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    I loved this program I want more
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    If you would like to see different content, please email
                    your suggestions to{" "}
                    <Link to="mailto:admin@hannahealth.com" target="_blank">
                    admin@hannahealth.com
                    </Link>
                    .
                  </div>
                </div>
              </div>
            </div>
            <p />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offer;
