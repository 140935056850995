import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";
import useApiAxios from "../../api/useApiAxios";
import logoSm from "./../../assets/images/logo_sm.png";
import logo from "./../../assets/images/hanna1.png";
import user from "./../../assets/images/user.png";
import ImageComponent from "../media/ImageComponent";

const NavBar2 = () => {
  const { sideBarEnable, setSideBarEnable, userData } = useContext(Context);
  const { pathname } = useLocation();
  const { logOutApi } = useApiAxios();

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <a href="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoSm} alt="VIRT" />
              </span>
              <span className="logo-lg">
                <img src={logo} alt="VIRT" />
              </span>
            </a>
          </div>
          <button
            className={
              pathname.startsWith("/question/playlist")
                ? "btn btn-sm ps-1 ps-md-3 px-md-3 menu-hamburger"
                : "hideItem hideItemTo btn btn-sm ps-1 ps-md-3 px-md-3 menu-hamburger header-item"
            }
            type="button"
            id="vertical-menu-btn"
            onClick={() => setSideBarEnable(!sideBarEnable)}
          >
            <i className="icon-5"></i>
          </button>
        </div>
        <div className="d-flex align-items-center">
          <div className="dropdown d-inline-block d-xl-none ms-2">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-search-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></button>
            <div
              className="dropdown-menu dropdown-menu-end py-0 px-3"
              aria-labelledby="page-header-search-dropdown"
            ></div>
          </div>

          <div className="credit_sms ms-2 d-flex align-items-center">
            <div className="dropdown d-inline-flex profile_dropdown language"></div>
            <div className="dropdown d-inline-flex profile_dropdown">
              <button
                type="button"
                className="btn w-100 header-item waves-effect d-flex justify-content-between align-items-center position-relative"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <ImageComponent
                  className="rounded-circle header-profile-user me-2"
                  src={
                    userData.profileImage ? `${userData.profileImage}` : user
                  }
                />
                <span
                  className="d-none d-md-inline-block ms-1 text-start pe-2 pe-md-4"
                  key="t-henry"
                >
                  <strong>{userData?.name}</strong>
                </span>
                <i className="fa fa-angle-down down_arrow"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-end fullOnSmall">
                <Link to="/profile" className="dropdown-item">
                  <i className="icon-11 align-middle me-1"></i>
                  <span key="t-profile">Account Profile</span>
                </Link>
                <Link to="/change/password" className="dropdown-item">
                  <i className="icon-11 align-middle me-1"></i>
                  <span key="t-profile">Change Password</span>
                </Link>

                <div className="dropdown-divider"></div>
                <Link onClick={logOutApi} className="dropdown-item text-danger">
                  <i className="fa fa-power-off align-middle me-1 text-danger"></i>
                  <span key="t-logout">Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar2;
