import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import useApiAxios from "../../api/useApiAxios";
import painLevelIcon from "./../../assets/images/painscale.jpg";
import cros from "../../assets/images/cros.svg";

const PainScaleModel = ({ show, handleClose }) => {
  const [painLevel, setPainLevel] = useState("");
  const { createPainLabelApi } = useApiAxios();

  const handleRadioChange = (event) => {
    setPainLevel(event.target.value);
  };

  const handleSubmit = async () => {
    try {
    
      await createPainLabelApi(painLevel);
      handleClose(); 
    } catch (error) {
      // Handle errors
      console.error("Failed to create pain label:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">How is your pain level?</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
      <div className="modal-body text-start">        
        <div className="col-12 pt-2 pb-4 text-center">
          <img src={painLevelIcon} alt="Pain Scale" className="img-fluid m-auto" style={{ maxHeight: "inherit" }} />
        </div>
        <div>
          {[...Array(11)].map((_, index) => (
            <div key={index} className="form-check form-check-inline mb-2">
              <input
                className="form-check-input"
                type="radio"
                name="painLevel"
                id={`painLevel-${index}`}
                value={index}
                checked={painLevel === `${index }`}
                onChange={handleRadioChange}
              />
              <label
                className="form-check-label"
                htmlFor={`painLevel-${index }`}
              >
                {index }
              </label>
            </div>
          ))}
        </div>
        <div className="col-md-12 mt-4 d-flex justify-content-center">
          <button
            className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PainScaleModel;
