import React, { useEffect, useContext } from "react";
import logo from "./../../assets/images/hanna1.png";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../context/Context";
import { Navbar, Nav } from "react-bootstrap";

const NavBar = () => {
  const { pathname } = useLocation();
  const { isAuthorized, userData } = useContext(Context);
  const getLink = () => {
    if (
      (userData.subscription?.status === "active" ||
        userData?.company?.subscription?.status === "active") &&
      !userData.currentUserAnswer &&
      userData.address
    ) {
      return "/intro";
    } else if (
      userData?.subscription?.status === "inactive" &&
      userData?.type === "physicalTherapist"
    ) {
      return "/subscribe";
    } else if (
      userData?.company?.subscription?.status === "inactive" &&
      userData?.type === "company"
    ) {
      return "/company/payment/pending";
    } else if (
      (userData?.subscription || userData?.type === "company") &&
      userData?.currentUserAnswer &&
      userData?.address
    ) {
      if (userData?.currentUserAnswer?.completed) {
        return `/question/playlist/${userData?.currentUserAnswer._id}`;
      } else {
        return `/question/d-pain/${userData?.currentUserAnswer._id} `;
      }
    } else if (
      (userData.subscription || userData.type === "company") &&
      !userData.address
    ) {
      return "/profile";
    } else {
      return "/subscribe";
    }
  };
  const renderDemoLink = () => {
    return (
      <Nav.Link
        as={Link}
        to={isAuthorized ? getLink() : "/login"}
        style={{ marginTop: "10px" }}
      >
        <span className="book-btn">
          {isAuthorized ? "GO TO DASHBOARD" : "Login"}
        </span>
      </Nav.Link>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      const scrollThreshold = 10;
      const backgroundColor1 = "#ffffff00";
      const backgroundColor2 = "#ffffff";

      const newBackgroundColor =
        scroll > scrollThreshold ? backgroundColor2 : backgroundColor1;
      document.querySelector("nav").style.backgroundColor = newBackgroundColor;
      document.getElementById("back-to-top").style.display =
        scroll > scrollThreshold ? "block" : "none";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="navbar fixed-top navbar-expand-lg navbar-light home_nev"
      >
        <div className="container-fluid px-0">
          <Navbar.Brand as={Link} to="/" className="logo-lg">
            <span>
              <img src={logo} title="VIRT" alt="" />
            </span>
          </Navbar.Brand>
          <Navbar.Toggle
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          />
          <Navbar.Collapse id="basic-navbar-nav" className="navbar-nav-scroll">
            <Nav className="ms-auto my-2 my-lg-0">
              <Nav.Link>
                <li className="nav-item">
                  <Link
                    className={
                      pathname === "/individual"
                        ? "active nav-link"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="individual"
                  >
                    INDIVIDUAL
                  </Link>
                </li>
              </Nav.Link>
              <Nav.Link>
                <li className="nav-item">
                  <Link
                    className={
                      pathname === "/employers" ? "active nav-link" : "nav-link"
                    }
                    aria-current="page"
                    to="employers"
                  >
                    EMPLOYERS
                  </Link>
                </li>
              </Nav.Link>
              <Nav.Link>
                <li className="nav-item">
                  <Link
                    className={
                      pathname === "/physical-therapists"
                        ? "active nav-link"
                        : "nav-link"
                    }
                    to="physical-therapists"
                  >
                    {" "}
                    PHYSICAL THERAPISTS
                  </Link>
                </li>
              </Nav.Link>

              {renderDemoLink()}
              {/* <Nav.Link> */}
              <li className="nav-item d-flex align-items-center">
                <Link
                  className={"nav-link d-flex align-items-center"}
                  to="tel:(615)212-5360"
                >
                  <i className="icon-31 phone-icon me-2"></i> (615) 212-5360 /
                  (441) 732-7030
                </Link>
              </li>
              {/* </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div
        id="back-to-top"
        onClick={scrollToTop}
        className="back-to-top"
        role="button"
      >
        <i className="fa fa-chevron-up" title="Go To Top"></i>
      </div>
    </>
  );
};

export default NavBar;
