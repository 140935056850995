import React, { useState } from "react";
import useApiAxios from "../../api/useApiAxios";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import VideoComponent from "../media/VideoComponent";
import EndDQuestionPopupModal from "./EndDQuestionPopupModal";

const DynamicQuestion = () => {
  const {
    getDynamicUserQuestionApi,
    createUserQuestionAnswerApi,
    removePlaylistApi,
  } = useApiAxios();
  const [answerData, setAnswerData] = useState();
  const [question, setQuestion] = useState();
  const [currentAnswer, setCurrentAnswer] = useState();
  const [nextVideo, setNextVideo] = useState();
  const [someThingWentWrong, setSomeThingWentWrong] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [loadingSkeleton, setLoadingSkeleton] = useState(true);

  const { id } = useParams();

  const navigator = useNavigate();
  const init = async () => {
    setLoadingSkeleton(true);
    const { data, error } = await getDynamicUserQuestionApi(id);
    if (!error) {
      if (data.data.completed) {
        navigator(`/question/playlist/${id}`);
      }
      setAnswerData(data.data);
      setQuestion(
        data.data.nextQuestion
          ? data.data.nextQuestion
          : data?.data?.painHab?.question[0]
      );
      setSomeThingWentWrong(
        data.data.nextQuestion
          ? data.data.nextQuestion
            ? false
            : true
          : data?.data?.painHab?.question[0]
          ? false
          : true
      );
    }
    setLoadingSkeleton(false);
  };
  console.log(currentAnswer?.finished);
  const handleOptionChange = async (item) => {
    setCurrentAnswer(item);
  };
  const handleRestart = async () => {
    await removePlaylistApi();
  };
  console.log(currentAnswer);
  const handleNext = async () => {
    console.log("first");
    if (!nextVideo && currentAnswer.nextVideo) {
      setNextVideo(currentAnswer.nextVideo);
    } else {
      const { data, error } = await createUserQuestionAnswerApi(currentAnswer);
      if (!error) {
        setCurrentAnswer();
        setQuestion();
        setNextVideo();
        init();
      }
    }
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <EndDQuestionPopupModal
        show={openModal}
        handleClose={setOpenModal}
        functionCall={handleNext}
      />
      {nextVideo ? (
        <div className="container banner_area px-3 px-md-0 ">
          <div className="container banner_area px-3 px-md-0">
            <div className="d-flex step-footer">
              {!someThingWentWrong && (
                <Link
                  onClick={handleRestart}
                  className="step_btn d-inline-block ms-auto"
                >
                  Restart Process
                </Link>
              )}
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-xl-10 col-lg-10 z-10 pt-4">
              <h1 className="step_title text-center mb-3 ">
                {nextVideo.title}
              </h1>
              <VideoComponent
                src={`${nextVideo.filename}`}
                className={`w-100 `}
              />
              <div
                dangerouslySetInnerHTML={{ __html: nextVideo?.description }}
              />

              <div className="d-flex step-footer mb-4">
                <Link
                  onClick={() => handleNext()}
                  className={`step_btn d-inline-block ms-auto ${""}`}
                >
                  Next step
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container banner_area px-3 px-md-0">
            <div className="d-flex step-footer">
              {!someThingWentWrong && (
                <Link
                  onClick={handleRestart}
                  className="step_btn d-inline-block ms-auto"
                >
                  Restart Process
                </Link>
              )}
            </div>
          </div>

          <div className="">
            <div className="container mb-4 mb-xl-5 pt-3 ">
              <div className="row d-flex align-items-stretch justify-content-center">
                <div className="col-xl-10 col-lg-10 mb_24">
                  <div className="step_area">
                    <h4
                      className={`question-title pb-3  ${
                        question?.title ? "" : "nameSkeleton"
                      }`}
                    >
                      {someThingWentWrong
                        ? "Some Thing went wrong please click on restart "
                        : question?.title}
                    </h4>
                    {someThingWentWrong ? (
                      <></>
                    ) : (
                      <p
                        className={`question-title pb-3  ${
                          question?.description ? "" : "descriptionSkeleton"
                        }`}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question?.description,
                          }}
                        />
                      </p>
                    )}
                    {question?.optionAction.map((item) => (
                      <div className="form-check py-2 pe-2">
                        <input
                          className="form-check-input mr-1"
                          type="radio"
                          name="emp-1"
                          defaultValue=""
                          id="remember1"
                          onChange={() => handleOptionChange(item)}
                        />
                        <label
                          className="form-check-label font14"
                          htmlFor="remember1"
                        >
                          {item.title}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex step-footer">
                    {someThingWentWrong ? (
                      <Link
                        onClick={() => handleRestart()}
                        className={`step_btn d-inline-block ms-auto ${""}`}
                      >
                        Restart
                      </Link>
                    ) : (
                      <Link
                        onClick={() =>
                          currentAnswer?.finished
                            ? setOpenModal(true)
                            : handleNext()
                        }
                        className={`step_btn d-inline-block ms-auto ${""}`}
                      >
                        Next step
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DynamicQuestion;
