import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import pain from "./../json/pain.json";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context";

const Step1Yes = () => {
  const { createActivitiesLogsApi } = useApiAxios();
  const [activities, setActivities] = useState([
    { activity: "Activity 1" },
    { activity: "Activity 2" },
    { activity: "Activity 3" },
  ]);
  const navigate = useNavigate();
  const { userData } = useContext(Context);
  useEffect(() => {
    if (userData.activities.length !== 0) {
      navigate("/question/painQuestion");
    }
  }, [userData.name]);

 
  const createActivitiesHandler = async () => {
    try {
      const { error } = await createActivitiesLogsApi({ activities });
      if (!error) {
        navigate("/question/painQuestion");
      }
    } catch (error) {}
  };
  const handleTextChange = (name, e, type) => {
    if (type === "text") {
      let value = e.target.value;
      const nextShapes = activities.map((activity) => {
        if (activity.activity === name) {
          return { ...activity, answer: value };
        } else {
          return activity;
        }
      });
      setActivities(nextShapes);
    } else {
      const nextShapes = activities.map((activity) => {
        if (activity.activity === name) {
          return { ...activity, painLabel: e };
        } else {
          return activity;
        }
      });
      setActivities(nextShapes);
    }
  };

  return (
    <div className="container banner_area px-3 px-md-0">
      <div className="row d-flex justify-content-center align-items-center">
        <div className="col-xl-10 col-lg-10 z-10">
          <p className="step_boldsubtext text-center">
            Please identify three important activities you are unable to perform
            or are having difficulty with as a result of your problem and rate
            (on an 10-point scale) the current level of difficulty associated
            with each activity.
          </p>
          <p className="step_boldsubtext text-center">
            E.g.: sitting, walking, bending, lifting, reaching, picking items up
            off the floor, carrying weights, sleeping.
          </p>
        </div>
        <div className="row d-flex align-items-stretch justify-content-center">
          <div className="col-xl-10 col-lg-10 my-5 mb_24">
            {activities.map((item) => (
              <div className="step_area min-h-360">
                <div className="row col-12">
                  <h4 className="question-title">{item.activity}</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        handleTextChange(item.activity, e, "text")
                      }
                      placeholder="Your answer"
                    />
                  </div>
                  <div className="row mt-3">
                    <div className="col-sm-3 mb-3 pain-level text-start">
                      Unable to perform activity
                    </div>
                    <div className="col-sm-6 mb-3">
                      <div className="row text-center">
                        {pain.map(({ painLevel }) => (
                          <div className="col-lg custom-radiocontainer">
                            <div className="custom-radio">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`inlineRadio${item.activity}`}
                                id={`inlineRadio${item.activity}${painLevel}`}
                                defaultValue="option1"
                                onChange={() =>
                                  handleTextChange(
                                    item.activity,
                                    painLevel,
                                    "radio"
                                  )
                                }
                              />
                            </div>
                            <div className="custom-radio-level px-1 py-1">
                              <label
                                className="form-check-label"
                                htmlFor={`inlineRadio${item.activity}${painLevel}`}
                              >
                                {painLevel}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-sm-3 mb-3 pain-level text-start">
                      Able to perform activity at the same level as before
                      injury or problem
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex step-footer">
              <Link
                to="/question"
                className="step_btnoutline d-inline-block me-auto"
              >
                Previous
              </Link>
              <Link
                href="condition-step-3.html"
                className="step_btn d-inline-block ms-auto"
                onClick={() =>
                  activities.length === 3 ? createActivitiesHandler() : true
                }
              >
                Next
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1Yes;
