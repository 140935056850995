import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import MetisMenu from "@metismenu/react";
import { Context } from "../../context/Context";

const SideBarCourse = ({
  children,
  selectedVideoModule,
  setCurrentVideoToPlay,
  currentVideoToPlay,
  setCurrentUserIdVideo,
  activePlaylistIndex,
  setActivePlaylistIndex,
}) => {
  const { sideBarEnable, setSideBarEnable } = useContext(Context);

  return (
    <div className="container-fluid  ">
      <div className=" d-none d-md-block bg-light " id="sidebar-menu">
        <div className="vertical-menu">
          <div data-simplebar className="h-100 py-3">
            <div id="sidebar-menu">
              <MetisMenu
                triggerElement="div"
                className="list-unstyled mb-5"
                id="side-menu"
              >
                <Accordion
                  className="video_list"
                  defaultActiveKey={0}
                  activeKey={activePlaylistIndex}
                >
                  {selectedVideoModule.map((item, index) => (
                    <Accordion.Item
                      key={index}
                      eventKey={index}
                      onClick={() => setActivePlaylistIndex(index)}
                    >
                      <Accordion.Header className="p-3">
                        {item?.videoModule?.title}
                      </Accordion.Header>
                      <Accordion.Body>
                        {item.userVideos.map((item2, index2) => (
                          <div
                            key={index2}
                            className="video_class"
                            onClick={() => {
                              setCurrentVideoToPlay(item2.video);
                              setCurrentUserIdVideo(item._id);
                            }}
                            style={{
                              display: "flex", // Align items in one row
                              alignItems: "center", // Vertically align the items

                              borderRadius:
                                item2.video._id === currentVideoToPlay?._id
                                  ? "0 25px 25px 0"
                                  : "",
                              backgroundColor:
                                item2.video._id === currentVideoToPlay?._id
                                  ? "#ecedea"
                                  : "",
                            }}
                          >
                            <div
                              className="video-image"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                height: "64px",
                                width:"64px",
                                overflow: "hidden",
                                position: "relative",
                              }}
                            >
                              {item2?.video?.presignedThumbnailUrl && (
                                <img
                                  alt="Thumbnail"
                                  src={item2?.video?.presignedThumbnailUrl}
                                  style={{
                                    height: "100%",
                                  }}
                                />
                              )}
                            </div>

                            <div
                              className={`class_name ${
                                sideBarEnable ? "" : "hideItem"
                              }`}
                            >
                              <h5 className="mb-1 mt-2">
                                {item2?.video?.title}
                              </h5>
                            </div>
                          </div>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </MetisMenu>
            </div>
          </div>
        </div>
      </div>

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SideBarCourse;
