import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import cros from "../../assets/images/cros.svg";
import { Context } from "../../context/Context";

const VideoPopupModal = ({
  show,
  handleClose,
  currentUserIdVideo,
  nextVideoHandler,
  removePlaylistApi,
  index,
  setIndex,
  lastVideo,
}) => {
  const { userData, setOpenCalendlyModel } = useContext(Context);
  const close = () => {
    handleClose();
    setIndex(0);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <Modal.Header>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <img src={cros} alt="" />
        </button>
      </Modal.Header>
      <Modal.Body>
        {index === 0 ? (
          <div className="col-md-12">
            <h1 className="home_title text-center mb-3">
              Nice Work, {userData?.name}
            </h1>
            <p className="steppopup_boldsubtext text-center mb-5">
              <span className="d-inline-block d-md-block">
                What would you like to do next?
              </span>{" "}
            </p>
            <div className="row">
              {!lastVideo ? (
                <div
                  className="col-md-12"
                  onClick={() => {
                    nextVideoHandler(currentUserIdVideo);
                    close();
                  }}
                >
                  <div className="ratings">
                    <label className="rating-item">
                      <input type="radio" name="rating-option" />
                      <div className="rating-box">
                        <span className="rating-score">
                          <span>Continue with your exercise program</span>
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="col-md-12" onClick={removePlaylistApi}>
                <div className="ratings">
                  <label className="rating-item">
                    <input type="radio" name="rating-option" />
                    <div className="rating-box">
                      <span className="rating-score">
                        <span>Return to Main Menu (lose progress)</span>
                      </span>
                    </div>
                  </label>
                </div>
              </div>

              {userData?.physicalTherapist?.admin?.appointmentUrl?.url ? (
                <div
                  className="col-md-12 "
                  onClick={() => {
                    setOpenCalendlyModel(true);
                    handleClose();
                  }}
                >
                  <div className="ratings">
                    <label className="rating-item">
                      <input type="radio" name="rating-option" />
                      <div className="rating-box">
                        <span className="rating-score">
                          <span>Schedule Physical Therapy Visit</span>
                        </span>
                      </div>
                    </label>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="col-md-12">
            <h1 className="home_title text-center mb-3">
              We are adding this Very soon
            </h1>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-start pb-3">
        <div className="col-lg-12 text-center mt-2">
          <a
            href="#!"
            type="button"
            className="nice-btn grey mb-3 mb-md-0 mx-2"
            ata-bs-dismiss="modal"
            onClick={close}
          >
            Cancel
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoPopupModal;
