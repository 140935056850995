import React, { useContext } from "react"
import WellnessVideoLibrary from "../wellNessVideo/WellnessVideo";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";

const PhysicalTherapists = () => {
  const { content } = useContext(Context);
  return (
    <>
      <div>
        <div className="container-fluid banner_area ">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-5 order-2 order-md-1">
              <h1 className="banner-title mt-4 mt-md-0">{content.t76}</h1>
              <h6 className="banner-subtitle">{content.t77}</h6>
              <Link to="/contact" className="book1-btn">
                GET A DEMO
              </Link>
            </div>
            <div className="col-md-7 order-1 order-md-2 text-end">
              <ImageComponent
                className="img-fluid banner-img"
                src={`${content.img13}`}
              />
            </div>
          </div>
        </div>
        <div className="container my-4 my-xl-5 ">
          <div className="row py-4">
            <div className="col-sm-12">
              <h1 className="home_title text-center mb-3">{content.t78}</h1>
              <p className="home_subtext text-center">{content.t79}.</p>
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  <strong>{content.t80}</strong> {content.t81}
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <strong>{content.t82}</strong> {content.t83}
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  {content.t84}
                  <strong>{content.t85}</strong>.
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  {content.t86} <strong>{content.t87}</strong>.
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  {content.t88}
                  <strong>{content.t89}</strong>.
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <strong>{content.t90}</strong> {content.t91}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* VIRT Wellness Video Library */}
        <WellnessVideoLibrary />
        {/* VIRT Wellness Video Library end */}

        <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
          <div className="row py-4 py-xl-5 justify-content-center">
            <div className="col-xl-7">
              <h1 className="home_title text-center mb-5">{content.t92}</h1>
              <p className="home_subtext text-center">{content.t93}</p>
              <div className="row">
                <div className="col-md-12 text-center">
                  <Link to="/contact" className="book1-btn">
                    GET A DEMO
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhysicalTherapists;
