import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionForm from "./SubscriptionForm";
import { useParams } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import introVideo from "./../../assets/video/VIRT platform intro offer.mp4";

const PaymentPage = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const { plainStripeId, offerId } = useParams();
  const [offer, setOffer] = useState({});
  const { getOfferById } = useApiAxios();
  const fun = async () => {
    const data = await getOfferById(offerId);
    if (!data.error) {
      setOffer(data.data.data);
    }
  };
  useEffect(() => {
    fun();
  }, []);

  return (
    <div style={{ height: "100vh", display: "flex", alignItems: "center" }}>
      {" "}
      <Elements stripe={stripePromise}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="fw-bold login-title">{offer?.title}</h3>
              <div className="row align-items-center">
                {" "}
                {/* Aligning content */}
                <div className="col-sm-12">
                  <video
                    controlsList="nodownload"
                    width="100%"
                    height="300px"
                    controls
                  >
                    {" "}
                    {/* Adjusting video size */}
                    <source src={introVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
              <h3 className="fw-bold login-title">{"Hanna Health Program"}</h3>
              <div dangerouslySetInnerHTML={{ __html: offer.description }} />
            </div>
            <div
              className="col-md-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <SubscriptionForm offer={offer} plainStripeId={plainStripeId} />
            </div>
          </div>
        </div>
      </Elements>
    </div>
  );
};

export default PaymentPage;
