import React, { useState } from "react";
import { Link } from "react-router-dom";

const AskPainQuestion = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const isNextButtonDisabled = !selectedOption;
  return (
    <>
      <div className="container banner_area px-3 px-md-0">
       
      </div>
      {/* Banner end */}
      {/* VIRT Wellness Video Library */}
      <div className="">
        <div className="container mb-4 mb-xl-5 pt-3 ">
          <div className="row d-flex align-items-stretch justify-content-center">
            <div className="col-xl-10 col-lg-10 mb_24">
              <div className="step_area">
                <h4 className="question-title pb-3">
                  Are you experiencing any pain?
                </h4>
                <div className="form-check py-2 pe-2">
                  <input
                    className="form-check-input mr-1"
                    type="radio"
                    name="emp-1"
                    defaultValue=""
                    id="remember1"
                    onChange={() => handleOptionChange("Yes")}
                  />
                  <label
                    className="form-check-label font14"
                    htmlFor="remember1"
                  >
                    {" "}
                    Yes{" "}
                  </label>
                </div>
                <div className="form-check py-2 pe-2">
                  <input
                    className="form-check-input mr-1"
                    type="radio"
                    defaultChecked=""
                    name="emp-1"
                    defaultValue=""
                    id="remember2"
                    onChange={() => handleOptionChange("No")}
                  />
                  <label
                    className="form-check-label font14"
                    htmlFor="remember2"
                  >
                    {" "}
                    No{" "}
                  </label>
                </div>
              </div>
              <div className="d-flex step-footer">
                <Link
                  to={
                    !isNextButtonDisabled
                      ? selectedOption === "Yes"
                        ? "/question/select/pain/label"
                        : "/question/goal"
                      : ""
                  }
                  className={`step_btn d-inline-block ms-auto ${
                    isNextButtonDisabled ? "disabled" : ""
                  }`}
                >
                  Next step
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AskPainQuestion;
