import React, { useState } from "react";
import { Link } from "react-router-dom";
import typeOne from "../../assets/images/type1.svg";
import typeTwo from "../../assets/images/type2.svg";
import typeThree from "../../assets/images/type3.svg";
import typeFour from "../../assets/images/type4.svg";

const CourseQuestion = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const isNextButtonDisabled = !selectedOption;
  return (
    <>
      <div className="container banner_area px-3 px-md-0">
        <div className="row d-flex justify-content-center align-items-center pt-5 mt-5">
          <div className="col-xl-10 col-lg-10 z-10 pt-4">
            <h1 className="step_title text-center mb-3">Hi, Welcome to Hanna Health</h1>
            <p className="step_boldsubtext text-center">
              Please select the category below to get specific videos. Feel free
              to add as much detail as needed.
            </p>
          </div>
        </div>
      </div>
      {/* Banner end */}
      {/* VIRT Wellness Video Library */}
      <div className="">
        <div className="container mb-4 mb-xl-5 pt-3 z-10">
          <div className="row d-flex align-items-stretch justify-content-center">
            <div className="col-xl-10 col-lg-10 mb_24">
              <div className="step_area">
                <h4 className="question-title fw-bold mb-2">
                  What is your goal?
                </h4>
                <p className="question-subtitle pb-3">
                  Please select which service you are interested in.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="ratings">
                      <label className="rating-item">
                        <div>
                          <input
                            type="radio"
                            name="rating-option"
                            defaultValue="true"
                            onChange={() => handleOptionChange("Decrease pain")}
                          />
                          <div className="rating-box">
                            <span className="rating-score">
                              <img src={typeOne} alt="" />
                              <span>Decrease pain</span>
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ratings">
                      <label className="rating-item">
                        <input
                          type="radio"
                          name="rating-option"
                          defaultChecked=""
                          onChange={() =>
                            handleOptionChange(
                              "Reduce tightness and move better"
                            )
                          }
                        />
                        <div className="rating-box">
                          <span className="rating-score">
                            <img src={typeTwo} alt="" />
                            <span>Reduce tightness and move better</span>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ratings">
                      <label className="rating-item">
                        <input
                          type="radio"
                          name="rating-option"
                          onChange={() => handleOptionChange("Get stronger")}
                        />
                        <div className="rating-box">
                          <span className="rating-score">
                            <img src={typeThree} alt="" />
                            <span>Get stronger</span>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="ratings">
                      <label className="rating-item">
                        <input
                          type="radio"
                          name="rating-option"
                          onChange={() =>
                            handleOptionChange(
                              "Learn how to set up my desk and improve my posture"
                            )
                          }
                        />
                        <div className="rating-box">
                          <span className="rating-score">
                            <img src={typeFour} alt="" />
                            <span>
                              Learn how to set up my desk and improve my posture
                            </span>
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex step-footer">
                <Link
                  to="/question"
                  className="step_btnoutline d-inline-block me-auto"
                >
                  Previous step
                </Link>
                <Link
                  to={isNextButtonDisabled ? "#" : "/dashboard/step3"}
                  className={`step_btn d-inline-block ms-auto ${
                    isNextButtonDisabled ? "disabled" : ""
                  }`}
                >
                  Next step
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseQuestion;
