import React from "react";
import landingImage3 from "../../assets/images/virt1.png";

const LandingComponent3 = () => {
  return (
    <div className="container mt-5">
      <h4 className="fw-bold text-center mb-3" style={{ color: "#58be79" }}>
        INTRODUCING
      </h4>
      <h1 className="home_title text-center mb-3">
        <span>Hanna Health Wellness and</span> <br /> {/* First part */}
        <span>Prevention Program</span> {/* Second part */}
      </h1>

      <div className="row">
        <div className="col-md-7 mb-4 mb-md-0">
          <img
            src={landingImage3}
            className="img-fluid rounded shadow-lg"
            alt="Placeholder"
          />
        </div>
        <div className="col-md-5 d-flex align-items-center justify-content-center">
          <div>
            <div className="container ">
              <div className="row ">
                <div className="col-sm-12">
                  <h1 className="blue_text fw-bold">
                    Are You Ready To Take Control Of Your Health?
                  </h1>
                </div>
                <div className="col-sm-12">
                  <h5 className="mb-3"
                    style={{color:"#a3aed0",lineHeight:"26px", marginBottom: 0 }}
                  >
                    Go back to doing the things you love and stay mobile and
                    strong. You’ll have access to:
                  </h5>
                </div>
                <div className="col-sm-12">
                  <ul className="why-us">
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      Short follow-along exercise videos.
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      Exercises that fit into your busy lifestyle and can be
                      performed from anywhere.
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      Exercises for all fitness levels.
                    </li>
                    <li>
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      Simple, but effective movements
                    </li>
                  </ul>
                </div>
                <div className="col-sm-12">
                  <h5 className="" style={{color:"#a3aed0",lineHeight:"26px",marginBottom: 0 }}>
                    Discover a path to a more comfortable and enjoyable
                    lifestyle.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingComponent3;
