import React, { useContext, useEffect, useState } from "react";
import SnackbarComponent from "./components/snackbarComponent/SnackbarComponent";
import Loader from "./components/loader/Loader";
import useApiAxios from "./api/useApiAxios";

import Home from "./components/home/Home";
import Base from "./components/base/Base";
import { Route, Routes } from "react-router-dom";
import Employers from "./components/employers/Employers";
import PhysicalTherapists from "./components/physicalTherapists/PhysicalTherapists";
import Profile from "./components/Profile/Profile";
import Login from "./components/login/Login";
import { Context } from "./context/Context";
import Dashboard from "./components/dashboard/Dashboard";
import BaseLogin from "./components/baseLogin/BaseLogin";
import Step1 from "./components/steps/Step1";
import Goal from "./components/steps/Goal";
import Step3 from "./components/steps/Step3";
import Step5 from "./components/steps/Step5";
import Step6 from "./components/steps/Step6";
import Register from "./components/register/Register";
import Registered from "./components/register/Registered";
import Redirection from "./components/redirection/Redirection";
import Course from "./components/video/Course";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import Subscription from "./components/subscription/Subscription";
import Success from "./components/subscription/Success";
import Canceled from "./components/subscription/Canceled";
import CourseQuestion from "./components/questions/CourseQuestion";
import About from "./components/about/About";
import Faq from "./components/faq/Faq";
import Term from "./components/term/Term";
import Privacy from "./components/privacy/Privacy";
import PainQuestion from "./components/painQuestion/PainQuestion";
import ContactForm from "./components/contactForm/ContactForm";
import Question2 from "./components/painQuestion/Question2";
import Individual from "./components/individual/Individual";
import VideoFooter from "./components/videofooter/VideoFooter";
import Offer from "./components/offer/Offer";
import OfferById from "./components/offer/OfferById";
import PaymentPage from "./components/offer/PaymentPage";
import Step1Yes from "./components/steps/Step1Yes";
import AskPainQuestion from "./components/steps/AskPainQuestion";
import SelectBodyPart from "./components/steps/SelectBodyPart";
import SelectPainLabel from "./components/steps/SelectPainLabel";
import DynamicQuestion from "./components/steps/DynamicQuestion";
import ChangePassword from "./changePassword/ChangePassword";
import ReSuccess from "./components/subscription/ReSuccess";
import CompanyPaymentPending from "./components/subscription/CompanyPaymentPending";
import SubscriptionCompany from "./components/subscription/SubscriptionCompany";
const Routing = () => {
  const { isLoading, isAuthorized, userData, updateCount, contentUpdateCount } =
    useContext(Context);
  const [firstLoading, setFirstLoading] = useState(false);
  const { homeApi, getContentApi } = useApiAxios();
  useEffect(() => {
    checkLogin();
  }, [updateCount, contentUpdateCount]);
  const checkLogin = async () => {
    setFirstLoading(true);
    await getContentApi();
    await homeApi();
    setFirstLoading(false);
  };
  const [checkIsSecure, setCheckIsSecure] = useState(true);
  const [inputPassword, setInputPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  console.log(isAuthorized);
  return checkIsSecure ? (
    <>
      <Loader open={isLoading} />
      <SnackbarComponent />
      <Routes>
        <Route path="/subscription/canceled" element={<Canceled />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Base header={true} footer={true} />}>
          <Route path="/" element={<Home />} />
          <Route path="/employers" element={<Employers />} />
          <Route path="/physical-therapists" element={<PhysicalTherapists />} />

          <Route path="/individual" element={<Individual />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/term" element={<Term />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/painquestion" element={<PainQuestion />} />
          <Route path="/question2" element={<Question2 />} />
          <Route path="/contact" element={<ContactForm />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/vediofooter" element={<VideoFooter />} />
          <Route path="/contactform" element={<ContactForm />} />
        </Route>
        <Route path="/" element={<Base header={false} footer={false} />}>
          <Route path="/offer/:id" element={<OfferById />} />
          <Route path="/offer/:id/:type" element={<OfferById />} />
          <Route
            path="/payment/:offerId/:plainStripeId"
            element={<PaymentPage />}
          />
          <Route
            path="/payment/company/:offerId/:plainStripeId"
            element={<PaymentPage />}
          />
        </Route>
        <Route
          path="/company/payment/:id"
          element={<SubscriptionCompany type={"company"} />}
        />
        {firstLoading ? (
          <></>
        ) : isAuthorized ? (
          userData?.subscription?.status === "active" ||
          userData?.company?.subscription?.status === "active" ? (
            <>
              <Route path="/subscription/success" element={<Success />} />
              <Route
                path="/intro"
                element={<BaseLogin header={true} footer={true} />}
              >
                <Route path="/intro" element={<Dashboard />} />
              </Route>

              <Route
                path="/change/password"
                element={<BaseLogin header={true} footer={true} />}
              >
                <Route path="/change/password" element={<ChangePassword />} />
              </Route>

              <Route
                path="/question"
                element={<BaseLogin header={true} footer={false} />}
              >
                <Route path="/question" element={<Step1 />} />
                <Route path="/question/step/y" element={<Step1Yes />} />
                <Route
                  path="/question/painQuestion"
                  element={<AskPainQuestion />}
                />

                <Route path="/question/course" element={<CourseQuestion />} />
                <Route
                  path="/question/select/body/part/:painLabel"
                  element={<SelectBodyPart />}
                />
                <Route
                  path="/question/select/pain/label"
                  element={<SelectPainLabel />}
                />

                <Route path="/question/goal" element={<Goal />} />
                <Route
                  path="/question/goal/:painLabel/:bodyName"
                  element={<Goal />}
                />
                <Route
                  path="/question/d-pain/:id"
                  element={<DynamicQuestion />}
                />
                <Route path="/question/step3" element={<Step3 />} />
                <Route path="/question/playlist/:id" element={<Course />} />
                <Route path="/question/step5" element={<Step5 />} />
                <Route path="/question/step6" element={<Step6 />} />
              </Route>

              <Route
                path="/course"
                element={<BaseLogin header={true} footer={false} />}
              >
                <Route path="/course" element={<Course />} />
              </Route>

              <Route
                path="/subscription"
                element={<BaseLogin header={true} footer={false} />}
              >
                <Route path="/subscription" element={<Subscription />} />
              </Route>
              <Route path="/register/:id" element={<Registered />} />
              <Route
                path="/subscription/success/re/subscribe"
                element={<ReSuccess />}
              />
              <Route
                path="/company/payment/:id"
                element={<SubscriptionCompany type={"company"} />}
              />
            </>
          ) : (
            <>
              <Route path="/subscription/success" element={<Success />} />
              <Route path="/subscribe" element={<Subscription />} />
              <Route path="/register/:id" element={<Registered />} />
              <Route
                path="/company/payment/pending"
                element={<CompanyPaymentPending />}
              />

              <Route
                path="/subscription/success/re/subscribe"
                element={<ReSuccess type={"patient"} />}
              />
              <Route
                path="/company/payment/:id"
                element={<SubscriptionCompany type={"company"} />}
              />
            </>
          )
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/register/:id" element={<Register />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/subscription/success" element={<Success />} />
            <Route
              path="/subscription/success/re/subscribe"
              element={<ReSuccess />}
            />
            <Route
              path="/company/payment/:id"
              element={<SubscriptionCompany type={"company"} />}
            />

            <Route path="/*" element={<Redirection />} />
          </>
        )}
      </Routes>
    </>
  ) : (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="m-4 position-relative">
        <label htmlFor="password">Enter Password To Access Website</label>
        <input
          type="password"
          id="password"
          name="password"
          className="form-control"
          onChange={(e) => {
            setInputPassword(e.target.value);
            setInvalidPassword(false);
          }}
        />
        {invalidPassword ? (
          <small className="text-danger">{"Invalid Password"}</small>
        ) : (
          <></>
        )}
        <div className="m-4 position-relative d-flex justify-content-center">
          <button
            onClick={() => {
              if (inputPassword === "1zU8Uef43nLJ") {
                setCheckIsSecure("1zU8Uef43nLJ");
                setInvalidPassword(false);
                localStorage.setItem("secure", "1zU8Uef43nLJ");
              } else {
                setInvalidPassword(true);
              }
            }}
            className="btn btn-success"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default Routing;
