import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import useApiAxios from "../../api/useApiAxios";
import logo from "../../assets/images/hanna1.png";
import { Context } from "../../context/Context";

const Login = () => {
  // Get current year
  const currentYear = new Date().getFullYear();
  const {
    setError,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { loginApi } = useApiAxios();
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useContext(Context); // Assuming you have a setUser function in your Context

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const onSubmit = async (data) => {
    try {
      const response = await loginApi(data);
      if (response.error) {
        // Handle API errors
        setError("apiError", { message: response.data.message });
      } else {
        setUser(response.data.user); // Save user in context
      }
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="login_page">
      <div className="row align-self-stretch h-100">
        <div className="col-xl-6 col-lg-6 align-self-stretch h-100 login_box_mob order-2 order-lg-1">
          <div className="h-100 align-items-baseline login_box_outer">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="login-container m-auto"
            >
              <div className="row">
                <h3 className="fw-bold login-title">Sign In</h3>
                <p className="login-subtitle mb-4">
                  Sign in to start your session
                </p>
                <div className="col-12 mb-4 position-relative">
                  <label>
                    Email<span></span>
                  </label>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^\S+@\S+\.\S+$/,
                        message: "Invalid email format",
                      },
                    }}
                    render={({ field }) => (
                      <input
                        type="text"
                        placeholder="Enter Email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        {...field}
                      />
                    )}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">
                      {errors.email.message}
                    </div>
                  )}
                </div>
                <div
                  className="col-12 mb-4 position-relative"
                  id="show_hide_password"
                >
                  <label>Password</label>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: "Password is required" }}
                    render={({ field }) => (
                      <div className="input-group mb-3">
                        <input
                          name="password"
                          placeholder="Enter Password"
                          type={showPassword ? "text" : "password"}
                          className={`form-control ${
                            errors.password ? "is-invalid" : ""
                          }`}
                          {...field}
                        />
                        <span
                          onClick={togglePasswordVisibility}
                          className="input-group-text"
                        >
                          {showPassword ? (
                            <i className="fa-solid fa-eye"></i>
                          ) : (
                            <i className="fa-solid fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                    )}
                  />
                  {errors.password && (
                    <div style={{ color: "#dc3545" }}>
                      {errors.password.message}
                    </div>
                  )}
                </div>
                <div className="col-6 mb-4">
                  <div className="form-check">
                    <input
                      className="form-check-input mr-1"
                      type="checkbox"
                      id="remember"
                    />
                    <label
                      className="form-check-label font14"
                      htmlFor="remember"
                    >
                      Remember me
                    </label>
                  </div>
                </div>
                <div className="col-6 mb-4 text-end">
                  <Link to="/forgetPassword" className="blue_link_dark">
                    Forgot Password?
                  </Link>
                </div>
                <div className="col-12 mb-4">
                  <button
                    type="submit"
                    id="submit"
                    className="btn btn-block btn_login w-100"
                  >
                    Sign In
                  </button>
                </div>
                {errors.apiError && (
                  <div className="col-12 mb-4">
                    <div className="alert alert-danger">
                      {errors.apiError.message}
                    </div>
                  </div>
                )}
              </div>
            </form>
            <p className="copyright-text">
              © {currentYear} Hanna Health. All Rights Reserved
            </p>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 order-1 order-lg-2 pe-0">
          <div className="r-area">
            <div className="logo-r-area">
              <img alt="" src={logo} className="d-block login_img" />
            </div>
            <div className="logo-r-link">
              <ul>
                <li>
                  <Link to={`${process.env.REACT_APP_WEB_URL}/term`}>
                    Terms of Use
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
