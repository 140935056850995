import React, { useContext, useState } from "react";
import Footer from "../footer/Footer";
import NavBar2 from "../navBar/NavBar2";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import { Link, Outlet } from "react-router-dom";
import { Context } from "../../context/Context";
import { useCalendlyEventListener, PopupModal } from "react-calendly";
import useApiAxios from "../../api/useApiAxios";

const BaseLogin = ({ header, footer }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const { createAppointmentLeadApi } = useApiAxios();
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: async (e) => await createAppointmentLeadApi(),
    onPageHeightResize: (e) => console.log(e.data.payload.height),
  });

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const {
    sideBarEnable,
    userData,
    openCalendlyModel,
    setOpenCalendlyModel,
  } = useContext(Context);

  return (
    <>
      <div
        data-sidebar="light"
        data-layout-mode="light"
        className={sideBarEnable ? "sidebar-enable" : "vertical-collpsed"}
      >
        <ScrollToTop />
        {header ? <NavBar2 /> : <></>}
        <Outlet />
        {footer ? <Footer /> : <></>}
      </div>

      <a href="#!" className="add_bottom_btn" onClick={toggleMenu}>
        +
      </a>
      <div className="App">
        {userData?.physicalTherapist?.admin?.appointmentUrl?.url ? (
          <PopupModal
            style={{ zIndex: 100000000000 }}
            url={userData?.physicalTherapist?.admin?.appointmentUrl?.url}
            rootElement={document.getElementById("root")}
            onModalClose={() => setOpenCalendlyModel(false)}
            open={openCalendlyModel}
            prefill={{ email: userData.email, name: userData.name }}
          />
        ) : (
          <></>
        )}
        {menuVisible && (
          <div className="stylus-menu">
            {userData?.physicalTherapist?.admin?.appointmentUrl?.url ? (
              <Link
                className="btn btn-success mb-1"
                onClick={() => setOpenCalendlyModel(true)}
              >
                Book Appointment
              </Link>
            ) : (
              <></>
            )}
            {userData?.physicalTherapist?.phone ? (
              <Link
                className="btn btn-success mb-1"
                to={`tel:${userData?.physicalTherapist?.phone}`}
              >
                PT Phone {userData?.physicalTherapist?.phone}
              </Link>
            ) : (
              <></>
            )}
            {userData?.physicalTherapist?.email ? (
              <Link
                to={`mailto:${userData?.physicalTherapist?.email}`}
                className="btn btn-success mb-1"
              >
                Email {userData?.physicalTherapist?.email}
              </Link>
            ) : (
              <></>
            )}
            {/* Add Contact Support button */}
            <Link to="/contact" className="btn btn-success mb-1">
              Contact Support
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default BaseLogin;
