import React from 'react'
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import beverly from "./../../assets/images/Beverly.webp";
import ok from "./../../assets/images/ok.svg";
import luana from "./../../assets/images/luana.webp";
import carla from "./../../assets/images/Carla.webp";
import zach from "./../../assets/images/Zach.webp";
import CJ from "./../../assets/images/CJ.webp";
import Robin from "./../../assets/images/Robin.webp";

const Testimonial = () => {
  return (
   
    <div className="container mb-3">
    <div className="row pb-5 justify-content-center">
      <div className="col-xl-9 mb-5">
        <h1 className="home_title text-center mb-3">
          Client Testimonials
        </h1>
      </div>
      <div className="row">
        <div className="col-md-12">
         
           <OwlCarousel
                className="owl-theme testimonials"
                mouseDrag={false}
                margin={20}
                items={3}
                nav={false}
                loop={false}
                responsiveClass={true}
                responsive={{
                  0: {
                    items: 1,
                  },
                  767: {
                    items: 2,
                  },
                  991: {
                    items: 3,
                  },
                  1000: {
                    items: 3,
                  },
                }}
              >
            <div className="item">
              <div className="testimonial_box">
                <div className="row mb-4 pb-2">
                  <div className="col">
                    <div className="img-area">
                      <img
                        src={beverly}
                        title="Hanna Health Customer Beverly"
                        alt=""
                       
                      />
                    </div>
                  </div>
                  <div className="col text-end">
                    <div
                      className="Stars"
                      aria-label="Rating of this product is 2.3 out of 5."
                    />
                  </div>
                </div>
                <h4 className="mb-4 pb-2 testimonial-text">
                  "I started doing virtual classes and it really helped my
                  pain."
                </h4>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <h6 className="name">Beverly</h6>
                    <p className="ntype">Hanna Health Customer</p>
                  </div>
                  <div className="col text-end">
                    <img className="ok_text" src={ok} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item ">
              <div className="testimonial_box">
                <div className="row mb-4 pb-2">
                  <div className="col">
                    <div className="img-area">
                      <img
                        src={luana}
                        title="Hanna Health Customer Luana"
                        alt="luana"
                      />
                    </div>
                  </div>
                  <div className="col text-end">
                    <div
                      className="Stars"
                      aria-label="Rating of this product is 2.3 out of 5."
                    />
                  </div>
                </div>
                <h4 className="mb-4 pb-2 testimonial-text">
                  "I`ve been doing online classes and adapting the
                  movements, finding great results."
                </h4>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <h6 className="name">Luana </h6>
                    <p className="ntype">Hanna Health Customer</p>
                  </div>
                  <div className="col text-end">
                    <img className="ok_text" alt="" src={ok} />
                  </div>
                </div>
              </div>
            </div>
            <div className="item ">
              <div className="testimonial_box">
                <div className="row mb-4 pb-2">
                  <div className="col">
                    <div className="img-area">
                      <img
                        src={carla}
                        alt=""
                        title="Hanna Health Customer Carla"
                      />
                    </div>
                  </div>
                  <div className="col text-end">
                    <div
                      className="Stars"
                      aria-label="Rating of this product is 2.3 out of 5."
                    />
                  </div>
                </div>
                <h4 className="mb-4 pb-2 testimonial-text">
                  "Even with a `not in-person` class I can see results
                  quickly."
                </h4>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <h6 className="name">Carla</h6>
                    <p className="ntype">Hanna Health Customer</p>
                  </div>
                  <div className="col text-end">
                    <img className="ok_text" alt="" src={ok} />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial_box">
                <div className="row mb-4 pb-2">
                  <div className="col">
                    <div className="img-area">
                      <img src={zach} alt="" title="Hanna Health Customer Zach" />
                    </div>
                  </div>
                  <div className="col text-end">
                    <div
                      className="Stars"
                      aria-label="Rating of this product is 2.3 out of 5."
                    />
                  </div>
                </div>
                <h4 className="mb-4 pb-2 testimonial-text">
                  "I high recommend Hanna Health and I am passing it on to my
                  family members."
                </h4>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <h6 className="name">Zach </h6>
                    <p className="ntype">Hanna Health Customer</p>
                  </div>
                  <div className="col text-end">
                    <img className="ok_text" src={ok} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial_box">
                <div className="row mb-4 pb-2">
                  <div className="col">
                    <div className="img-area">
                      <img src={CJ} title="Hanna Health Customer CJ" alt="" />
                    </div>
                  </div>
                  <div className="col text-end">
                    <div
                      className="Stars"
                      aria-label="Rating of this product is 2.3 out of 5."
                    />
                  </div>
                </div>
                <h4 className="mb-4 pb-2 testimonial-text">
                  "I was not really focusing on my flexibility and now I
                  have specific exercises for it"
                </h4>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <h6 className="name">CJ</h6>
                    <p className="ntype">Hanna Health Customer</p>
                  </div>
                  <div className="col text-end">
                    <img className="ok_text" src={ok} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="item ">
              <div className="testimonial_box">
                <div className="row mb-4 pb-2">
                  <div className="col">
                    <div className="img-area">
                      <img
                        src={Robin}
                        alt=""
                        title="Hanna Health Customer Robin"
                      />
                    </div>
                  </div>
                  <div className="col text-end">
                    <div
                      className="Stars"
                      aria-label="Rating of this product is 2.3 out of 5."
                    />
                  </div>
                </div>
                <h4 className="mb-4 pb-2 testimonial-text">
                  "Doing the stretches and exercises I feel much more
                  comfortable."
                </h4>
                <div className="row d-flex align-items-center">
                  <div className="col">
                    <h6 className="name">Robin</h6>
                    <p className="ntype">Hanna Health Customer</p>
                  </div>
                  <div className="col text-end">
                    <img className="ok_text" alt="" src={ok} />
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Testimonial