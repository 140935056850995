import React, { useContext } from "react";
import { Link } from "react-router-dom";
import WellnessVideoLibrary from "../wellNessVideo/WellnessVideo";
import Testimonial from "../testimonial/Testimonial";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";

const Individual = () => {
  const { content } = useContext(Context);
  return (
    <div>
      <div className="container-fluid banner_area pe-0">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-5 order-2 order-md-1">
            <h1 className="banner-title mt-4 mt-md-0">
              {content.t23}
            </h1>
            <h6 className="banner-subtitle">{content.t24}</h6>
          </div>
          <div className="col-md-7 order-1 order-md-2 text-end">
            <ImageComponent
              src={`${content.img11}`}
              className="img-fluid banner-img"
            />
          </div>
        </div>
      </div>

      <div className="container my-4 my-xl-5 ">
        <div className="row py-4">
          <div className="col-sm-12">
            <p className="home_subtext text-center">{content.t25}</p>
            <div className="row">
              <div className="col-md-12 text-center">
                <Link to="/contact" className="book1-btn">
                  I want to sign up for Hanna Health{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-4 my-xl-5 ">
        <div className="row py-4">
          <div className="col-sm-12">
            <h1 className="home_title text-center mb-3">{content.t26}</h1>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                <strong>{content.t27}</strong>
                {content.t28}
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <strong>{content.t29}</strong>
                {content.t30}
              </li>
              <li>
                <i className="fas fa-check-circle" />
                {content.t31} <strong>{content.t32}</strong>
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                {content.t33}
                <strong>{content.t34}</strong>
              </li>
              <li>
                <i className="fas fa-check-circle" />
                {content.t35}
                <strong>{content.t36}</strong>
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <strong>{content.t37}</strong> {content.t38}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <WellnessVideoLibrary />
      <Testimonial />
      <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
        <div className="row py-4 py-xl-5 justify-content-center">
          <div className="col-xl-7">
            <h1 className="home_title text-center mb-5">{content.t39}</h1>
            <div className="row">
              <div className="col-md-12 text-center">
                <Link to="/contact" className="book1-btn">
                  I want to sign up for Hanna Health{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Individual;
