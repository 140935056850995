import React from "react";
import Mariana from "../../assets/images/Mariana-200x200.jpg";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <div className="container my-4 my-xl-5 pt-4 pt-xl-5">
        <div className="row py-4">
          <h1 className="home_title mb-3">Our mission</h1>
          <p className="home_subtext">
            Hanna Health's mission is to enhance the quality of life for individuals by
            promoting proactive lifestyle management through access to resources
            and exercises on our wellness video platform. We aim to assist
            people in living more active, healthy, and pain-free lives by
            providing high-quality education and movement.
          </p>
        </div>
      </div>
      {/* About end */}
      {/* VIRT mission  */}
      <div className="about-mission mb-4 mb-xl-5 mt-4 mt-xl-5" />
      {/* VIRT mission end */}
      {/* serve */}
      <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
        <div className="row py-4 py-xl-5 justify-content-center">
          <div className="col-xl-7">
            <h1 className="home_title text-center mb-3">Our History</h1>
            <p className="home_subtext">
              Mariana Parks, the founder, brings over 12 years of experience as
              a physical therapist and pilates instructor. Throughout her
              career, she has worked with numerous clients facing joint
              stiffness, limited mobility, and lack of strength, often resulting
              in pain and injuries.
            </p>
            <p className="home_subtext">
              <img src={Mariana} className="about-img-potrait" />
              Recognizing commonalities among these conditions, she developed a
              solution aimed at assisting individuals in addressing common
              ailments BEFORE they escalate into injuries requiring medical
              attention or physical therapy. Additionally, a self-care movement
              plan is provided to help people self-manage their conditions.
            </p>
            <p className="home_subtext">
              At Hanna Health, we prioritize proactive lifestyle management and firmly
              believe that prevention and education are the best approaches to
              making a meaningful impact on people's lives, sparing them the
              challenging, lengthy, and costly journey through the healthcare
              system.
            </p>
          </div>
        </div>
      </div>
      <div className="container mb-4 mb-xl-5 pb-4 pb-xl-5">
        <div className="row py-4 py-xl-5 justify-content-center">
          <div className="col-xl-9">
            <h1 className="home_title text-center mb-5">
              Discover the transformative power of our program!
            </h1>
            <div className="row">
              <div className="col-md-12 text-center">
                <Link to="/contact" className="book1-btn">
                  CONTACT US
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
