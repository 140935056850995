import React from "react";
import Footer from "../footer/Footer";
import NavBar from "../navBar/NavBar";
import ScrollToTop from "../scrollToTop/ScrollToTop";
import { Outlet } from "react-router-dom";

const Base = ({ header, footer }) => {
  return (
    <>
      <ScrollToTop />
      {header ? <NavBar /> : <></>}
      <Outlet />
      {footer ? <Footer /> : <></>}
    </>
  );
};

export default Base;
