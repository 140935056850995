import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useApiAxios from "../../api/useApiAxios";
const SubscriptionForm = ({ plainStripeId, offer }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { createIntentSubscriptionApi, checkAdminEmailApi, checkUserEmailApi } =
    useApiAxios();
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true); // State to track email validity

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      agree: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("User Name is required"),
      agree: Yup.string().required("Please agree to T&C"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      // registerApi({ ...values, offer: id });
      if (!values.agree) {
        return formik.setFieldError("agree", "Please agree to T&C");
      }

      if (!stripe || !elements) {
        return;
      }

      const cardElement = await elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.error(error.message);
      } else {
        await createIntentSubscriptionApi({
          ...values,
          planId: plainStripeId,
          paymentId: paymentMethod.id,
          offerId: offer._id,
          createdFor: offer.createdFor,
        });

        // Send the paymentMethod.id to your server to complete the payment
      }
    },
  });
  console.log(formik.errors);

  const checkAdminEmailOnChange = async (e) => {
    formik.handleChange(e);
    const { error, data } = await checkAdminEmailApi({ email: e.target.value });
    if (error) {
      formik.setErrors({ email: data });
      setIsEmailValid(false); // Set email as invalid
    } else {
      formik.setErrors({ email: null });
      setIsEmailValid(true); // Set email as valid
    }
  };

  const checkUserEmailOnChange = async (e) => {
    formik.handleChange(e);
    const { error, data } = await checkUserEmailApi({ email: e.target.value });
    if (error) {
      formik.setErrors({ email: data });
      setIsEmailValid(false); // Set email as invalid
    } else {
      setIsEmailValid(true); // Set email as valid
      formik.setErrors({ email: null });
    }
  };

  const handleEmailChange = (e) => {
    if (offer.createdFor === "patient") {
      checkUserEmailOnChange(e);
    } else {
      checkAdminEmailOnChange(e);
    }
  };
  return (
    <div className="login-container m-auto">
      <div className="row scrollable-div">
        <form onSubmit={formik.handleSubmit}>
          <div className="col-12 mb-4 position-relative">
            <label htmlFor="name">
              {offer.createdFor === "patient" ? "Full Name" : "Company Name"}{" "}
              <span>*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="JohnDoe"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <small className="text-danger">{formik.errors.name}</small>
            )}
          </div>
          <div className="col-12 mb-4 position-relative">
            <label htmlFor="email">
              Email<span>*</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-control"
              placeholder="Your Email"
              onChange={handleEmailChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {isEmailValid ? (
              formik.errors.email && (
                <small className="text-danger">{formik.errors.email}</small>
              )
            ) : (
              <small className="text-danger">{"Email already in use."}</small>
            )}
          </div>

          <div className="mb-4">
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: "#424770",
                    "::placeholder": {
                      color: "#aab7c4",
                    },
                    padding: "10px 12px",
                    lineHeight: "24px",
                    display: "block",
                    width: "100%",
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
              }}
            />
          </div>
          <div className="col-12 mb-4 position-relative">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={true}
                id="agree"
                name="agree"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label className="form-check-label" htmlFor="agree">
                I have read and agree to the&nbsp;
                <Link to="/term" target="_blank">
                  terms and conditions
                </Link>
                &nbsp;of this page as follows.
              </label>
            </div>
           
          </div>
          <button
            type="submit"
            className="btn btn-block btn_login w-100"
            disabled={!stripe || loading || !isEmailValid}
          >
            {loading ? "Processing..." : "Pay"}
            {offer?.threeMonth?.stripePlanId === plainStripeId
              ? `$ ${offer?.threeMonth?.price} every three months`
              : offer?.sixMonth?.stripePlanId === plainStripeId
              ? `$ ${offer?.sixMonth?.price} every six months`
              : offer?.year?.stripePlanId === plainStripeId
              ? `$ ${offer?.year?.price} every year`
              : "00"}
          </button>
        </form>
      </div>{" "}
    </div>
  );
};

export default SubscriptionForm;
