import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import useApiAxios from "../../api/useApiAxios";
import cros from "../../assets/images/cros.svg";

const SatisfactionScaleModel = ({ show, handleClose }) => {
  const [satisfactionLevel, setSatisfactionLevel] = useState();
  const { createSatisfactionLabelApi } = useApiAxios();

  const handleOptionChange = (event) => {
    setSatisfactionLevel(parseInt(event.target.value));
  };

  const handleSubmit = async () => {
    try {
      await createSatisfactionLabelApi(satisfactionLevel);
      handleClose(); 
    } catch (error) {
      // Handle errors
      console.error("Failed to create pain label:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      className="modal fade success-popup add-company"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          Please indicate your level of agreement with the following statement:
          'I am satisfied with my experience today’.
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        >
          <img src={cros} alt="" />
        </button>
      </div>

      <div className="modal-body text-start">
        <div className="mb-3">
          <div className="form-check mb-2 my-5">
            <input
              className="form-check-input"
              type="radio"
              name="satisfactionLevel"
              id="stronglyDisagree"
              value="0"
              checked={satisfactionLevel === 0}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="stronglyDisagree">
              Strongly Disagree
            </label>
          </div>
          <div className="form-check mb-2 my-5">
            <input
              className="form-check-input"
              type="radio"
              name="satisfactionLevel"
              id="disagree"
              value="25"
              checked={satisfactionLevel === 25}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="disagree">
              Disagree
            </label>
          </div>
          <div className="form-check mb-2 my-5">
            <input
              className="form-check-input"
              type="radio"
              name="satisfactionLevel"
              id="neutral"
              value="50"
              checked={satisfactionLevel === 50}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="neutral">
              Neutral
            </label>
          </div>
          <div className="form-check mb-2 my-5">
            <input
              className="form-check-input"
              type="radio"
              name="satisfactionLevel"
              id="agree"
              value="75"
              checked={satisfactionLevel === 75}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="agree">
              Agree
            </label>
          </div>
          <div className="form-check mb-2 my-5">
            <input
              className="form-check-input"
              type="radio"
              name="satisfactionLevel"
              id="stronglyAgree"
              value="100"
              checked={satisfactionLevel === 100}
              onChange={handleOptionChange}
            />
            <label className="form-check-label" htmlFor="stronglyAgree">
              Strongly Agree
            </label>
          </div>
        </div>
        <div className="col-md-12 mt-4 d-flex justify-content-center">
          <button
            className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
            onClick={handleSubmit}
            disabled={satisfactionLevel === null}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SatisfactionScaleModel;
