import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import cros from "./../../assets/images/cros.svg";
import Modal from "react-bootstrap/Modal";
import WellnessVideoLibrary from "../wellNessVideo/WellnessVideo";
import useApiAxios from "../../api/useApiAxios"; // Import the useApiAxios hook
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Context } from "../../context/Context";
import ImageComponent from "../media/ImageComponent";
import VideoComponent from "../media/VideoComponent";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .max(20, "First Name must be 20 characters or less"),
  lastName: Yup.string()
    .required("Last Name is required")
    .max(20, "Last Name must be 20 characters or less"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .max(50, "Email must be 50 characters or less"),
  phone: Yup.string()
    .required("Phone Number is required")
    .max(10, "Phone Number must be 10 digits")
    .matches(/^[0-9]+$/, "Invalid phone number"),
  organization: Yup.string()
    .required("Organization is required")
    .max(50, "Organization must be 50 words or less"),
});

const Employers = () => {
  const { getWhitePaper } = useApiAxios(); // Destructure the postMessage function from the hook
  const [show, setShow] = useState(false);
  const { content } = useContext(Context);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await getWhitePaper(values);
      handleClose(); // Close the modal on success
    } catch (error) {
      console.error("Error:", error);
      // Handle error if needed
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {/* banner */}
      <div className="container-fluid banner_area pe-0">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-5 order-2 order-md-1">
            <h1 className="banner-title mt-4 mt-md-0">{content.t40}</h1>
            <h6 className="banner-subtitle">{content.t41}</h6>

            <Link to="/contact" className="book1-btn">
              GET A DEMO
            </Link>
          </div>
          <div className="col-md-7 order-1 order-md-2 text-end">
            <ImageComponent
              className="img-fluid banner-img"
              src={`${content?.img12}`}
              alt=""
              title="Wellness video platform to prevent & reduce pain"
            />
          </div>
        </div>
      </div>
      {/* Banner end */}
      {/* Why us Start */}
      <div className="container my-4 my-xl-5 pt-4 pt-xl-5">
        <div className="row py-4">
          <div className="col-sm-12">
            <h1 className="home_title text-center mb-4 mb-xl-5">
              {content.t42}
            </h1>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                <strong>
                  {content.t43}
                  <span className="text_green"> {content.t44}</span>
                </strong>
                {content.t45}
              </li>
              <li>
                <i className="fas fa-check-circle" />
                {content.t46} <strong>{content.t47}</strong> {content.t48}
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <strong>{content.t49} </strong>
                {content.t50}
                <strong>
                  <span className="text_green">{content.t51}</span>
                </strong>
              </li>
            </ul>
          </div>
          <div className="col-sm-6">
            <ul className="why-us">
              <li>
                <i className="fas fa-check-circle" />
                {content.t52}
                <strong>{content.t53}</strong> {content.t54}
              </li>
              <li>
                <i className="fas fa-check-circle" />
                {content.t55}
                <strong>{content.t56}</strong>
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <strong>{content.t57}</strong> {content.t58}
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Why us end */}
      <>
        {/* VIRT Wellness Video  */}
        <div className="video_shade mt-4 mb-5">
          <div className="container mb-4 mb-xl-5 pb-4 pb-xl-5 pt-4 pt-xl-5 ">
            <div className="row pb-5">
              <div className="col-sm-12">
                {content?.video1 ? (
                  <VideoComponent src={`${content?.video1}`} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* VIRT Wellness Video  end */}

        <div className="container my-4 my-xl-5 pt-4 pt-xl-5">
          <div className="row py-4">
            <div className="col-sm-12">
              <h1 className="home_title text-center mb-4 mb-xl-5">
                {content.t59}
              </h1>
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  {content.t60}
                  <strong>{content.t61}</strong>
                  {content.t62}
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  {content.t63} <strong>{content.t64}</strong> {content.t65}
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul className="why-us">
                <li>
                  <i className="fas fa-check-circle" />
                  {content.t66}
                  <strong>{content.t67}</strong>
                  {content.t68}
                </li>
                <li>
                  <i className="fas fa-check-circle" />
                  <strong>{content.t69}</strong>
                  {content.t70}
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* VIRT white paper  */}
        <div className="musculoskeletal mb-4 mb-xl-5 mt-4 mt-xl-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 p-5">
                <h1 className="musculoskeletal-title">{content.t71}</h1>
              </div>
              <div className="col-lg-6 bg-white rounded-4 p-4">
                <h2 className="text-center py-3">{content.t72}</h2>
                <p className="home_subtext text-center">{content.t73}</p>
                <div className="text-center">
                  <button
                    className="btn btn-danger mb-4 btn-lg rounded-5"
                    onClick={handleShow}
                  >
                    Get The Paper
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* VIRT white paper  end */}
      </>

      {/* VIRT Wellness Video Library */}
      <WellnessVideoLibrary />
      {/* VIRT Wellness Video Library end */}

      {/* serve */}
      <div className="container mb-4 mb-xl-5 pt-4 pb-4 pb-xl-5">
        <div className="row py-4 py-xl-5 justify-content-center">
          <div className="col-xl-7">
            <h1 className="home_title text-center mb-3">{content.t74}</h1>
            <p className="home_subtext text-center">{content.t75}</p>
            <div className="row">
              <div className="col-md-12 text-center">
                <Link to="/contact" className="book1-btn">
                  GET A DEMO
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* serve end */}
      {/* model */}
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        className="modal fade success-popup add-company"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            Get The Paper
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <img src={cros} alt="" />
          </button>
        </div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            organization: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="modal-body text-start">
                <div className="row ">
                  <div className="col-lg-12 mb-4">
                    <div className="product-input text-start">
                      <Field
                        type="text"
                        name="firstName"
                        className="form-control"
                        placeholder="First Name"
                        maxLength={20}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="product-input text-start">
                      <Field
                        type="text"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        maxLength={20}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="product-input text-start">
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        maxLength={50}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="product-input text-start">
                      <Field
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Phone Number"
                        maxLength={10}
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="product-input text-start">
                      <Field
                        type="text"
                        name="organization"
                        className="form-control"
                        placeholder="Organization"
                        maxLength={50}
                      />
                      <ErrorMessage
                        name="organization"
                        component="div"
                        className="error-message"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-start pb-3">
                <button
                  type="button"
                  className="btn addsubmit-btn grey-btn me-3 mb-3 px-3 px-sm-5"
                  onClick={handleClose}
                >
                  Go Back
                </button>
                <button
                  type="submit"
                  className="btn addsubmit-btn green-btn mb-3 px-3 px-sm-5"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Get The Paper"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Employers;
