import React from "react";

const CompanyPaymentPending = () => {
  return (
    <div>
      Your company subscription has expired. If you are the manager / owner than
      contact PT
    </div>
  );
};

export default CompanyPaymentPending;
