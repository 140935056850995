import React, { useState } from 'react';
import { Link } from 'react-router-dom'

const Step3 = () => {

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (option) => {
      setSelectedOption(option);
    };
  
    const isNextButtonDisabled = !selectedOption;
  return (
    <>
    <div className="container banner_area px-3 px-md-0">
      <div className="row d-flex justify-content-center align-items-center pt-5 mt-5">
        <div className="col-xl-10 col-lg-10 z-10 pt-4">
          <h1 className="step_title text-center mb-3">Hi, Welcome to Hanna Health</h1>
          <p className="step_boldsubtext text-center">
            Please select the category below to get specific videos. Feel free to
            add as much detail as needed.
          </p>
        </div>
      </div>
    </div>
    {/* Banner end */}
    {/* VIRT Wellness Video Library */}
    <div className="">
      <div className="container mb-4 mb-xl-5 pt-3 z-10">
        <div className="row d-flex align-items-stretch justify-content-center">
          <div className="col-xl-10 col-lg-10 mb_24">
            <div className="step_area min-h-360">
              <h4 className="question-title pb-3 ">Do you want to:</h4>
              <div className="form-check py-2 pe-2">
                <input
                  className="form-check-input mr-1"
                  type="radio"
                  defaultChecked=""
                  name="emp-1"
                  defaultValue=""
                  id="remember1"
                  onChange={() => handleOptionChange('RelaxMuscle')}
                />
                <label className="form-check-label font14" htmlFor="remember1">
                  {" "}
                  Find stretches to relax muscle tightness and tension{" "}
                </label>
              </div>
              <div className="form-check py-2 pe-2">
                <input
                  className="form-check-input mr-1"
                  type="radio"
                  name="emp-1"
                  defaultValue=""
                  id="remember2"
                  onChange={() => handleOptionChange('ReduceStiffness')}
                />
                <label className="form-check-label font14" htmlFor="remember2">
                  {" "}
                  Exercises to help reduce your joint stiffness, improve your
                  mobility and prevent injuries?{" "}
                </label>
              </div>
            </div>
            <div className="d-flex step-footer">
              <Link
                to="/dashboard/step2"
                className="step_btnoutline d-inline-block me-auto"
              >
                Previous step
              </Link>
              <Link
              to={isNextButtonDisabled ? '#' : '/dashboard/step4'}
              className={`step_btn d-inline-block ms-auto ${
                isNextButtonDisabled ? 'disabled' : ''
              }`}
            >
              Next step
            </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default Step3